<div class="row">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-striped table-bordered caption-top">
        <caption>รายละเอียดสิทธิประกันสุขภาพทั้งหมดของบุคคลนี้</caption>
        <thead>
        <tr>
          <th nowrap scope="col">วันที่เปลี่ยนแปลงสิทธิ</th>
          <th nowrap scope="col">ลำดับการเปลี่ยนแปลงสิทธิรายวัน</th>
          <th nowrap scope="col">จังหวัดที่ลงทะเบียนรักษา</th>
          <th nowrap scope="col">สิทธิการรักษาพยาบาล</th>
          <th nowrap scope="col">ประเภทสิทธิย่อย/สิทธิที่ใช้เบิก</th>
          <th nowrap scope="col">รหัสบัตรประกันสุขภาพ</th>
          <th nowrap scope="col">วันที่เริ่มใช้หน่วยบริการ(UC)/วันที่เริ่มสิทธิ(SSS)</th>
          <th nowrap scope="col">วันหมดสิทธิย่อย(UC)/วันที่หมดสิทธิ(SSS)</th>
          <th nowrap scope="col">หน่วยบริการรับส่งต่อ(UC)/รพ.รักษา(SSS/OFC)</th>
          <th nowrap scope="col">หน่วยบริการปฐมภูมิ</th>
          <th nowrap scope="col">หน่วยบริการประจำ</th>
          <th nowrap scope="col">สถานะการเปลี่ยนแปลงสิทธิ</th>
        </tr>
        </thead>
        <tbody>
          @for (history of histories; let idx = $index; track history) {
            <tr>
              <td class="col-date">{{(history.transDate | thaidatetime:{showTime:true}) || '-'}}</td>
              <td>{{history.groupId || '-'}}</td>
              <td>{{history.provinceName || '-'}}</td>
              <td nowrap>{{history.mainInsclDesc || '-'}}</td>
              <td nowrap>{{history.subInsclDesc || '-'}}</td>
              <td>{{history.cardId || '-'}}</td>
              <td class="col-date">{{(history.startDate | thaidatetime:{showTime:true} )|| '-'}}</td>
              <td class="col-date">{{(history.expireDate | thaidatetime:{showTime:true} )|| '-'}}</td>
              <td nowrap>{{history.hospMainName || '-'}}</td>
              <td nowrap>{{history.hospSubName || '-'}}</td>
              <td nowrap>
                @if (history.mainInscl == 'SSS') {
                  <!--{{todo }}-->
                } @else {
                  {{ history.hospMainOpName|| '-' }}
                }
              </td>
              <td nowrap>{{history.statusDesc || '-'}}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
