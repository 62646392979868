<form #form="ngForm" class="form-validate" id="request">
  <div class="container-fluid mb-4">
    <div class="row mt-3">
      <label class="col-xl-2 fw-bold text-primary">ข้อมูลที่อยู่ปัจจุบัน</label>
      <div class="col-auto">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" [(ngModel)]="request().addressType"
                 (change)="onAddressTypeChange()" name="addressType"
                 id="address_type_match" value="MATCH">
          <label class="form-check-label" for="address_type_match">ตรงตามทะเบียนบ้าน</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" [(ngModel)]="request().addressType"
                 (change)="onAddressTypeChange()" name="addressType"
                 id="address_type_mismatch" value="MISMATCH">
          <label class="form-check-label" for="address_type_mismatch">ไม่ตรงตามทะเบียนบ้าน</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" [(ngModel)]="request().addressType"
                 (change)="onAddressTypeChange()" name="addressType"
                 id="address_type_pined" value="PINNED">
          <label class="form-check-label" for="address_type_pined">เลือกที่อยู่โดยปักหมุด</label>
        </div>
      </div>
    </div>
    <div class="container mt-3" id="address">
      <div class="row">
        <div class="col-xl-6 col-sm-12 d-xl-flex">
          <label for="building-type"
                 class="col-form-label col-xl-3 col-sm-12 ui-require">ประเภทที่อยู่:</label>
          <div class="col-xl-8 col-sm-12">
            <app-select-building-type id="building-type"
                                      name="buildingType"
                                      required
                                      [(ngModel)]="request().buildingType"
                                      #buildingType="ngModel">
            </app-select-building-type>
            @if (buildingType.invalid && (buildingType.dirty || buildingType.touched)) {
              <custom-error *ngIf="buildingType.hasError('required')" error="*จำเป็น"></custom-error>
            }
          </div>
        </div>
      </div>
      <div class="row mt-xl-3 mt-sm-0">
        <div class="col-xl-6 col-sm-12 d-xl-flex">
          <label for="addressNo"
                 class="col-form-label col-xl-3 col-sm-12">บ้านเลขที่/อาคาร:</label>
          <div class="col-xl-8 col-sm-12">
            <input type="text" id="addressNo" name="addressNo"
                   class="form-control" [(ngModel)]="request().addressNo">
          </div>
        </div>
        <div class="col-xl-6 col-sm-12 d-xl-flex mt-sm-3 mt-xl-0">
          <label for="moo"
                 class="col-form-label col-xl-3 col-sm-12">หมู่:</label>
          <div class="col-xl-8 col-sm-12">
            <input type="text" id="moo" name="moo" class="form-control" [(ngModel)]="request().moo">
          </div>
        </div>
      </div>
      <div class="row mt-xl-3 mt-sm-0">
        <div class="col-xl-6 col-sm-12 d-xl-flex">
          <label for="alley"
                 class="col-form-label col-xl-3 col-sm-12">ตรอก:</label>
          <div class="col-xl-8 col-sm-12">
            <input type="text" id="alley" name="trok" class="form-control" [(ngModel)]="request().trok">
          </div>
        </div>
        <div class="col-xl-6 col-sm-12 d-xl-flex">
          <label for="soi" class="col-form-label col-xl-3 col-sm-12">ซอย:</label>
          <div class="col-xl-8 col-sm-12">
            <input type="text" id="soi" name="soi" class="form-control" [(ngModel)]="request().soi">
          </div>
        </div>
      </div>
      <div class="row mt-xl-3 mt-sm-0">
        <div class="col-xl-6 col-sm-12 d-xl-flex">
          <label for="road"
                 class="col-form-label col-xl-3 col-sm-12">ถนน:</label>
          <div class="col-xl-8 col-sm-12">
            <input type="text" id="road" name="road" class="form-control" [(ngModel)]="request().road">
          </div>
        </div>
        <div class="col-xl-6 col-sm-12 d-xl-flex">
          <label for="subDistrict" class="col-form-label col-xl-3 col-sm-12 ui-require">ตำบล/แขวง:</label>
          <div class="col-xl-8 col-sm-12">
            <input type="text" id="subDistrict" name="subDistrict"
                   [ngModel]="request()?.subDistrict?.name"
                   class="form-control-plaintext" formDisabled>
          </div>
        </div>
      </div>
      <div class="row mt-xl-3 mt-sm-0">
        <div class="col-xl-6 col-sm-12 d-xl-flex">
          <label for="district"
                 class="col-form-label col-xl-3 col-sm-12 ui-require">อำเภอ/เขต:</label>
          <div class="col-xl-8 col-sm-12">
            <input type="text" id="district" name="district" class="form-control-plaintext"
                   [ngModel]="request().subDistrict?.district?.name" formDisabled>
          </div>
        </div>
        <div class="col-xl-6 col-sm-12 d-xl-flex">
          <label for="province"
                 class="col-form-label col-xl-3 col-sm-12 ui-require">จังหวัด:</label>
          <div class="col-xl-8 col-sm-12">
            <input type="text" id="province" name="province" class="form-control-plaintext"
                   [ngModel]="request().subDistrict?.district?.province?.name" formDisabled>
          </div>
        </div>
      </div>
      <div class="row mt-xl-3 mt-sm-0">
        <div class="col-xl-6 col-sm-12 d-xl-flex">
          <label for="mobile" class="col-form-label col-xl-3 col-sm-12 ui-require">เบอร์โทรศัพท์มือถือ:</label>
          <div class="col-xl-8 col-sm-12">
            <input type="text" id="mobile" name="mobile" placeholder="กรอกเฉพาะตัวเลข" class="form-control"
                   #mobile="ngModel"
                   pattern="^\d{10}$"
                   required
                   [(ngModel)]="request().contactInfo.mobile">
            @if (mobile.invalid && (mobile.dirty || mobile.touched)) {
              <custom-error *ngIf="mobile.hasError('required')" error="*จำเป็น"></custom-error>
              <custom-error *ngIf="mobile.hasError('pattern')"
                            error="ระบุเป็นตัวเลข ความยาวเท่ากับ 10"></custom-error>
            }
          </div>
        </div>
        <div class="col-xl-6 col-sm-12 d-xl-flex">
          <label for="tel"
                 class="col-form-label col-xl-3 col-sm-12">เบอร์โทรศัพท์:</label>
          <div class="col-xl-8 col-sm-12">
            <input type="text" id="tel" placeholder="เบอร์โทรศัพท์หากมีมากกว่า 1 เบอร์ให้ใส่,คั่น"
                   name="tel"
                   class="form-control"
                   maxlength="120"
                   [(ngModel)]="request().contactInfo.tel">
          </div>
        </div>
      </div>
      <div class="row mt-xl-3 mt-sm-0">
        <div class="col-xl-6 col-sm-12 d-xl-flex">
          <label for="email"
                 class="col-form-label col-xl-3 col-sm-12">อีเมล:</label>
          <div class="col-xl-8 col-sm-12">
            <input type="email" id="email" name="email" class="form-control" autocomplete="off" email #email="ngModel"
                   [(ngModel)]="request().contactInfo.email">
            @if (email.invalid && (email.dirty || email.touched)) {
              <custom-error *ngIf="email.hasError('email')" error="email ไม่ถูกต้อง"></custom-error>
            }
          </div>
        </div>
        <div class="col-xl-6 col-sm-12 d-xl-flex">
          <label for="lineId"
                 class="col-form-label col-xl-3 col-sm-12">Line ID:</label>
          <div class="col-xl-8 col-sm-12">
            <input type="text" id="lineId" name="lineId" class="form-control"
                   [(ngModel)]="request().contactInfo.lineId">
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex mt-5">
      <div class="col-auto col-form-label">
        <div class="fw-bold text-primary">เครือข่ายหน่วยบริการของผู้ลงทะเบียน</div>
      </div>
      @if (mastercup.invalid && (mastercup.dirty || mastercup.touched)) {
        <custom-error *ngIf="mastercup.hasError('required')" error="*จำเป็น"></custom-error>
      }
      <div class="col-xl-9">
        <div class="row d-flex mb-3">
          <div class="col-12 text-xl-start text-sm-start">
            <button class="btn btn-outline-primary fw-bold mt-2 me-2" (click)="selectMastercup()">
              <i class="bi bi-hospital"></i> เลือกเครือข่ายหน่วยบริการ
            </button>
          </div>
        </div>
        <div class="mt-3 d-xl-flex justify-content-start mb-3">
          <div class="col-xl-8 col-sm-12 mt-3" id="result">
            <div class="card">
              <div class="card-body">
                <div class="mt-3 d-xl-flex">
                  <label for="mastercupId"
                         class="col-form-label col-xl-4 col-sm-12">รหัสเครือข่าย:</label>
                  <div class="col-xl-8 col-sm-12">
                    <input type="text" id="mastercupId" name="mastercupId"
                           [ngModel]="request()?.mastercup?.mastercupId"
                           class="form-control-plaintext"  required #mastercup="ngModel">
                  </div>
                </div>
                <div class="mt-3 d-xl-flex">
                  <label for="provinceNew"
                         class="col-form-label col-xl-4 col-sm-12">จังหวัดที่ลงทะเบียน:</label>
                  <div class="col-xl-8">
                    <input type="text" id="provinceNew" name="provinceName"
                           [ngModel]="request()?.mastercup?.provinceName"
                           class="form-control-plaintext">
                  </div>
                </div>
                <div class="mt-3 d-xl-flex">
                  <label for="hmainOp"
                         class="col-form-label col-xl-4 col-sm-12">หน่วยบริการประจำ:</label>
                  <div class="col-xl-8 col-sm-12">
                    <input type="text" id="hmainOp" name="hmainOp"
                           [ngModel]="request()?.mastercup?.hospMainOpDisplayName"
                           class="form-control-plaintext">
                  </div>
                </div>
                <div class="mt-3 d-xl-flex">
                  <label for="hsub"
                         class="col-form-label col-xl-4 col-sm-12">หน่วยบริการปฐมภูมิ:</label>
                  <div class="col-xl-8 col-sm-12">
                    <input type="text" id="hsub" name="hsub" [ngModel]="request()?.mastercup?.hospSubDisplayName"
                           class="form-control-plaintext">
                  </div>
                </div>
                <div class="mt-3 d-xl-flex">
                  <label for="hmain" class="col-form-label col-xl-4 col-sm-12">หน่วยบริการที่รับการส่งต่อ:</label>
                  <div class="col-xl-8 col-sm-12">
                    <input type="text" id="hmain" name="hmain"
                           [ngModel]="request()?.mastercup?.hospMainOpDisplayName"
                           class="form-control-plaintext">
                  </div>
                </div>
                <div class="mt-3 d-xl-flex">
                  <label for="model"
                         class="col-form-label col-xl-4 col-sm-12">รูปแบบการจัดสรรเงิน:</label>
                  <div class="col-xl-8 col-sm-12">
                    <input type="text" id="model" name="model" [ngModel]="request()?.mastercup?.paidModel"
                           class="form-control-plaintext">
                  </div>
                </div>
                <div class="my-3 d-xl-flex">
                  <label class="col-form-label col-xl-4">สถานะเครือข่าย:</label>
                  <div class="col-xl-8 col-sm-12">
                    <app-mastercup-status [mastercup]="request().mastercup"></app-mastercup-status>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  @if (request().addressType == 'MISMATCH') {
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-auto">
          <label class="text-primary fw-semibold fs-6 text-primary">หลักฐานการลงทะเบียน</label>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          @for (group of attachmentGroupList(); let idx = $index; track group.group) {
            <div fileGroupValid [fileGroupRequired]="group.required" #fileGroup="ngModelGroup"
                 [ngModelGroup]="'formGroup[' + group.group + ']'">
              <div class="row">
                <div class="col-auto">
                  <label class="col-form-label" style="line-height: 1.8rem;">
                    {{ group.name }}
                  </label>
                </div>
                <div class="col-auto p-0">
                  @if (fileGroup.invalid && (fileGroup.touched || fileGroup.dirty)) {
                    @if (fileGroup.hasError('fileGroup1Invalid')) {
                      <div class="form-text"><i class="bi bi-info-circle-fill text-danger me-2"></i>*บังคับแนบ</div>
                    } @else if (fileGroup.hasError('fileGroup2Invalid')) {
                      <div class="form-text"><i class="bi bi-info-circle-fill text-danger me-2"></i>*แนบอย่างไดอย่างหนึ่ง
                      </div>
                    } @else if (fileGroup.hasError('fileGroup3Invalid')) {
                      <div class="form-text"><i class="bi bi-info-circle-fill text-danger me-2"></i>*แนบอย่างน้อยหนึ่งอย่าง
                      </div>
                    }
                  } @else {
                    @if (group.required === 1) {
                      <div class="form-text"><i class="bi bi-info-circle-fill text-info me-2"></i>บังคับแนบ</div>
                    } @else if (group.required === 2) {
                      <div class="form-text"><i class="bi bi-info-circle-fill text-info me-2"></i>แนบอย่างไดอย่างหนึ่ง
                      </div>
                    } @else if (group.required === 3) {
                      <div class="form-text"><i class="bi bi-info-circle-fill text-info me-2"></i>แนบอย่างน้อยหนึ่งอย่าง
                      </div>
                    }
                  }
                </div>
              </div>
              <ol class="list-group file-upload-list">
                @for (file of request().filesMap | keyvalue: MAP_ORDER_BY_KEY_STRING_ASC | fileGroupFilter:group.group; track file.key) {
                  <li class="list-group-item justify-content-between align-items-start">
                    <div class="row">
                      <div class="col-xl-10">
                        <div
                          [ngClass]="{'ui-require': request().filesMap[file.key].fileType.attachmentGroup.required === 1}">
                          {{ file?.value?.fileType?.name }}
                        </div>
                        @if (file.key == '40') {
                          <div class="row">
                            <div class="col-12">
                              <div class="form-text">
                                รองรับไฟล์นามสกุล .zip .pdf ขนาดไม่เกิน 5 MB
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                      <div class="col-xl-2">
                        <app-file-upload id="filesMap[{{file.key}}]"
                                         name="filesMap[{{file.key}}]"
                                         [(ngModel)]="request().filesMap[file.key]"
                                         [fileType]="file.key == '40' ? ['pdf','zip', 'compress']: undefined"
                                         [fileSizeLimit]="5"
                                         [fileSize]="request().filesMap[file.key]?.fileSize"
                                         #fileUpload="ngModel"
                                         [fileRequired]="request().filesMap[file.key].fileType.attachmentGroup.required === 1">
                        </app-file-upload>
                        @if (fileUpload.invalid && (fileUpload.dirty || fileUpload.touched)) {
                          <div class="row">
                            <div class="col-12">
                              <custom-error *ngIf="fileUpload.hasError('required')"
                                            error="*กรุณาแนบไฟล์"></custom-error>
                              <custom-error *ngIf="fileUpload.hasError('fileRequired')"
                                            error="*กรุณาแนบไฟล์"></custom-error>
                              <custom-error *ngIf="fileUpload.hasError('fileSizeLimit')"
                                            error="*ขนาดต้องไม่เกิน 5 MB"></custom-error>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </li>
                }
              </ol>
            </div>
          }
        </div>
      </div>
    </div>
  }
  <div class="card shadow-card my-4">
    <div class="card-body">
      <div class="justify-content-xl-center justify-content-sm-start d-flex fw-bold mt-3">
        <input type="checkbox" class="form-check-input me-2" id="accept" name="accept" [(ngModel)]="request().accept"
               #accept="ngModel" required>
        <label for="accept" class="form-check-label">รับข้อตกลง และยืนยันการลงทะเบียน</label>
      </div>
      <div class="justify-content-xl-center justify-content-sm-start d-flex">
        @if (accept.invalid && (accept.dirty || accept.touched)) {
          <custom-error *ngIf="accept.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
    </div>
  </div>
  <div class="text-center mb-4">
    <div class="col-12">
      <button class="btn btn-success" type="button" (click)="confirmReg()">
        <i class="bi bi-floppy-fill me-1"></i>
        ยืนยันการลงทะเบียน
      </button>
    </div>
  </div>
</form>
