import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MyPage, RegMainCitizenDto, RegMainCitizenParams} from '../../generated-model/model'
import {environment} from '../../environments/environment';
import {IgnoreNullHttpParams} from '../shere/commons/Ignore-null-http-params';
@Injectable({
  providedIn: 'root'

})
export class RegMainCitizenResourceService {
  http=inject(HttpClient);

  public search(params:RegMainCitizenParams) {
    return this.http.get<MyPage<RegMainCitizenDto>>(`${environment.serverUrl}/api/secure/reg-main-history`,{
      params: IgnoreNullHttpParams.fromObject(params).toHttpParam()
    })
  }
}
