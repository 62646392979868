<form class="form-validate" #form="ngForm" (submit)="submit()">
  <div class="modal-header">
    <h6 class="modal-title text-danger">ข้อมูลจากแผนที่ไม่เพียงพอกรุณากรอกข้อมูลเพิ่มเติม</h6>
    <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.close('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <label class="col-xl-3 col-form-label ui-require">จังหวัด</label>
      <div class="col-xl-9">
        <app-select-mastercup-province id="selectedProvince"
                                       name="selectedProvince"
                                       [(ngModel)]="selectedProvince"
                                       (change)="onProvinceChange()"
                                       [disabled]="!!restrictProvince()"
                                       required
                                       [options]="options()"
                                       [defaultFirstVal]="false"
                                       #province="ngModel"></app-select-mastercup-province>
        @if (province.invalid && (province.dirty || province.touched)) {
          <custom-error *ngIf="province.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
    </div>
    <div class="row mb-3">
      <label for="selectedDistrict" class="col-xl-3 col-form-label ui-require">อำเภอ/เขต</label>
      <div class="col-xl-9">
        <app-select-mastercup-district id="selectedDistrict"
                                       name="selectedDistrict"
                                       [(ngModel)]="selectedDistrict"
                                       (ngModelChange)="onDistrictChange()"
                                       [provinceId]="selectedProvince()?.id"
                                       [disabled]="!selectedProvince()"
                                       required
                                       [options]="options()"
                                       #district="ngModel"></app-select-mastercup-district>
        @if (district.invalid && (district.dirty || district.touched)) {
          <custom-error *ngIf="district.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
    </div>
    <div class="row mb-3">
      <label for="selectedSubDistrict" class="col-xl-3 col-form-label ui-require">ตำบล/แขวง</label>
      <div class="col-xl-9">
        <app-select-mastercup-subdistrict id="selectedSubDistrict"
                                          name="selectedSubDistrict"
                                          [(ngModel)]="selectedSubDistrict"
                                          [districtId]="selectedDistrict()?.code"
                                          [disabled]="!selectedDistrict()"
                                          required
                                          [options]="options()"
                                          #subDistrict="ngModel"></app-select-mastercup-subdistrict>
        @if (subDistrict.invalid && (subDistrict.dirty || subDistrict.touched)) {
          <custom-error *ngIf="subDistrict.hasError('required')" error="*จำเป็น"></custom-error>
        }
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="activeModal.dismiss('cancel click')">ยกเลิก
    </button>
    <button type="submit" class="btn btn-custom btn-primary">เลือก</button>
  </div>
</form>
