import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {IgnoreNullHttpParams} from '../shere/commons/Ignore-null-http-params';
import {
  PersonSearchParams,
  PersonSearchResultDto,
  PublicRightCheckRequest,
  RightsDto
} from '../../generated-model/model';

@Injectable({providedIn: "root"})
export class RightCheckResource {
  http = inject(HttpClient);

  public searchPublicRight(params: PublicRightCheckRequest) {
    return this.http.get<RightsDto>(`${environment.serverUrl}/api/public/check`, {
      params: IgnoreNullHttpParams.fromObject(params).toHttpParam()
    })
  }

  public searchPersonWithBirthDate(params: PersonSearchParams) {
    return this.http.get<PersonSearchResultDto>(`${environment.serverUrl}/api/secure/search-person`, {
      params: IgnoreNullHttpParams.fromObject(params).toHttpParam()
    })
  }

  public searchRight(pid: string) {
    return this.http.get<RightsDto>(`${environment.serverUrl}/api/secure/check/${pid}`)
  }
}
