@if (showRegisterPanel()) {
  <form #form="ngForm" class="form-validate">
    <div class="card custom-box shadow-card mb-3">
      <div class="card-body">
        <ul ngbNav #navRegister="ngbNav" [(activeId)]="active" [destroyOnHide]="true" (navChange)="onRegTypeChange()"
            class="nav-pills justify-content-center mt-4">
          @if (showRegisterNew()) {
            <li [ngbNavItem]="1" [destroyOnHide]="true">
              <button ngbNavLink>
                ลงทะเบียนสิทธิว่างรายใหม่
              </button>
              <ng-template ngbNavContent>
                <app-register-new [request]="request()"></app-register-new>
              </ng-template>
            </li>
          }
          @if (showRegisterBoard()) {
            <li [ngbNavItem]="2" [destroyOnHide]="true">
              <button ngbNavLink>
                ยอมรับลงทะเบียนตามมติบอร์ด
              </button>
              <ng-template ngbNavContent>
                <app-accept-board [request]="request()"></app-accept-board>
              </ng-template>
            </li>
            <li [ngbNavItem]="3" [destroyOnHide]="true">
              <button ngbNavLink>
                แก้ไขลงทะเบียนตามมติบอร์ด
              </button>
              <ng-template ngbNavContent>
                <app-edit-board [request]="request()"></app-edit-board>
              </ng-template>
            </li>
          }
          @if (showChangeHospital()) {
            <li [ngbNavItem]="4" [destroyOnHide]="true">
              <button ngbNavLink>
                เปลี่ยนหน่วยบริการ
              </button>
              <ng-template ngbNavContent>
                <app-change-hospital-detail [request]="request()"></app-change-hospital-detail>
              </ng-template>
            </li>
          }
        </ul>
        <div [ngbNavOutlet]="navRegister" class="mt-2"></div>
      </div>
    </div>
  </form>
}
