import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Relation} from '../../generated-model/model';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RelationResourceService {
  http = inject(HttpClient)

  public getAllRelation() {
    return this.http.get<Relation[]>(`${environment.serverUrl}/api/secure/all-relation`)
  }

  public getRelationById(id:number){
    return this.http.get<Relation>(`${environment.serverUrl}/api/secure/relation-by-id/${id}` )
  }
}
