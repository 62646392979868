import {Directive, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormGroup, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {FileUploadDto} from "../../../generated-model/model";


@Directive({
  selector: '[fileGroupValid][ngModelGroup]',
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileGroupValidatorDirective),
      multi: true
    }
  ]
})
export class FileGroupValidatorDirective implements Validator, OnChanges {
  /**
   * 1 = บังคับแนบ
   * 2 = แนบอย่างใดอย่างหนึ่ง
   * 3 = แนบอย่างน้อยหนึ่งอย่าง
   */
  @Input()
  fileGroupRequired;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  /**
   * 1 = บังคับแนบ
   * 2 = แนบอย่างใดอย่างหนึ่ง
   * 3 = แนบอย่างน้อยหนึ่งอย่าง
   */
  validate(formGroup: FormGroup): ValidationErrors {
    // console.log('validate ', formGroup, this.fileGroupRequired)
    let valid = true;
    // let formTouched = Object.keys(formGroup.controls)
    //   .some(key => formGroup.controls[key].touched || formGroup.controls[key].dirty)
    if (this.fileGroupRequired === 2) {
      let controls = Object.keys(formGroup.controls)
        .map(key => formGroup.controls[key]);
      valid = controls
        .map(control => control.value)
        .filter((value: FileUploadDto) => !!value?.filename)
        .length > 1;
      if (!valid) {
        let errors = {};
        errors[`fileGroup2Invalid`] = true;
        console.log('errors: ', errors);
        return errors;
      }
    } else if (this.fileGroupRequired === 3) {
      let controls = Object.keys(formGroup.controls)
        .map(key => formGroup.controls[key]);
      valid = controls
        .map(control => control.value)
        .some((value: FileUploadDto) => !!value?.filename)
      if (!valid) {
        let errors = {};
        errors[`fileGroup3Invalid`] = true;
        console.log('errors: ', errors);
        return errors;
      }
    }
    return undefined;
  }

  registerOnValidatorChange?(fn: () => void): void {
  }

}
