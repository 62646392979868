import {
  AfterViewInit,
  Component,
  effect,
  forwardRef,
  inject,
  input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {finalize, Observable, of} from "rxjs";
import {CommonModule} from "@angular/common";
import {SharedCommonModule} from '../../../../../shere/commons/shared-common.module';
import {SubDistrict} from '../../../../../../generated-model/model';
import {MastercupResourceService} from '../../../../../service/mastercup-resource.service';

@Component({
  selector: 'app-select-mastercup-subdistrict',
  templateUrl: './select-mastercup-subdistrict.component.html',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectMastercupSubdistrictComponent),
      multi: true
    }
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedCommonModule
  ]
})
export class SelectMastercupSubdistrictComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {

  subDistrictList$: Observable<SubDistrict[]> = of([]);
  typeaheadLoading = false;
  value: SubDistrict = undefined;
  id = input<string>('select-mastercup-subdistrict');
  name = input<string>('select-mastercup-subdistrict');
  districtId = input<string>();
  disabled = input<boolean>();
  options = input<{ fetchAll?: boolean }>({fetchAll: false});
  private mastercupResource = inject(MastercupResourceService);
  onChange = (value) => {
  };
  onTouched = () => {
  };

  compareWithCode = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.code == o2.code))
  };

  constructor() {
    effect(() => {
      if (this.districtId()) {
        this.value = undefined
        this.subDistrictList$ = this.mastercupResource.findSubDistrictAllByDistrictId(this.districtId())
          .pipe(finalize(() => this.typeaheadLoading = false));
      }
    });
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
  }

  writeValue(value: SubDistrict) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  valueChange(value: SubDistrict) {
    this.onChange(value);
    this.onTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
}
