import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RelationshipRightMemberRequest, RelationshipRightsMember} from '../../generated-model/model';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RelationshipRightsMemberService {
  http = inject(HttpClient)

  public getMember(memberPid: string) {
    return this.http.get<RelationshipRightsMember>(`${environment.serverUrl}/api/secure/get-member/${memberPid}`)
  }

  public saveMember(request: RelationshipRightMemberRequest) {
    return this.http.post(`${environment.serverUrl}/api/secure/save-member`, request)
  }

  public getAllFamilyMember() {
    return this.http.get<RelationshipRightsMember[]>(`${environment.serverUrl}/api/secure/all-family-member`)
  }

  public deleteMember(memberPid: string) {
    return this.http.delete(`${environment.serverUrl}/api/secure/delete-member/${memberPid}`)
  }
}
