<div id="modal-content" class="modal-content">
  <div class="modal-body">
    @if (mode == 'CREATE') {
      <div class="container">
        <div class="row d-flex mt-3">
          <div class="col-10">
            <h5>
              <span class="fw-bold">ค้นหาและเพิ่มคนในครอบครัว</span>
            </h5>
          </div>
          <div class="col-2 text-end">
            <button class="btn-close" type="button" aria-label="Close" (click)="close()"></button>
          </div>
        </div>
        @if (result()?.personalId == null) {
          <form #form="ngForm" class="form-validate">
            <div class="container mt-3">
              <div class="row mt-5">
                <div class="col-xl-6 d-xl-flex">
                  <label for="searchPid" class="col-form-label col-xl-5 ui-require">เลขประจำตัวประชาชน</label>
                  <div class="col-xl-7">
                    <input type="text" id="searchPid" name="searchPid" [(ngModel)]="searchParams().pid"
                           class="form-control"
                           pattern="^\d{13}$" required #pid="ngModel">
                    <gt-error field = "searchPerson.params.pid.searchPid" forId="searchPid"></gt-error>
                    @if (pid.invalid && (pid.dirty || pid.touched)) {
                      <custom-error *ngIf="pid.hasError('required')" error="กรุณาระบุ"></custom-error>
                      <custom-error *ngIf="pid.hasError('pattern')"
                                    error="ระบุเป็นตัวเลข ความยาวเท่ากับ 13"></custom-error>
                    }
                  </div>
                </div>
                <div class="col-xl-6 d-xl-flex">
                  <label for="searchBirthDate" class="col-form-label col-xl-5 ui-require">วันเดือนปีเกิด</label>
                  <div class="col-xl-7">
                    <div class="input-group">
                      <input
                        id="searchBirthDate"
                        class="form-control"
                        placeholder="วว/ดด/ปปปป"
                        name="dp"
                        ngbDatepicker
                        [minDate]="{year:1900,month:1,day:1}"
                        [maxDate]="maxDate"
                        (dateSelect)="dateSelect($event)"
                        [(ngModel)]="searchParams().birthDate"
                        required
                        #d="ngbDatepicker"
                        #birthDateElement
                        #birthDate="ngModel">
                      <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()"
                              type="button"></button>
                    </div>
                    @if (birthDate.invalid && (birthDate.dirty || birthDate.touched)) {
                      <custom-error *ngIf="birthDate.hasError('required')" error="กรุณาระบุ"></custom-error>
                    }              </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-xl-6 d-xl-flex mb-3">
                  <label for="searchRelation" class="col-form-label ui-require col-xl-5">ความสัมพันธ์</label>
                  <div class="col-xl-7">
                    <select id="searchRelation" name="searchRelation" class="form-select"
                            [(ngModel)]="searchParams().relationId" required #relation="ngModel">
                      <option value="">กรุณาระบุ</option>
                      @for (item of relations(); let idx = $index; track item) {
                        <option [value]="item.id">{{ item.name }}</option>
                      }
                    </select>
                    @if (relation.invalid && (relation.dirty || relation.touched)) {
                      <custom-error *ngIf="relation.hasError('required')" error="กรุณาระบุ"></custom-error>
                    }
                  </div>
                </div>
                <div class="col-xl-6 text-xl-start text-center">
                  <button class="btn btn-primary" type="button" (click)="search()">
                    <span class="p-5">ค้นหา</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
          @if (resultNotfound) {
            <div class="container">
              <hr>
              <div class="mt-5">
                <h6 class="fw-bold">ผลการค้นหา</h6>
              </div>
              <div class="text-center text-danger">
                <h5>เลขประจำตัวประชาชน หรือ วันเดือนปีเกิดไม่ถูกต้อง</h5>
              </div>
            </div>
          }
        }
      </div>
      @if (result()?.personalId) {
        <form #form="ngForm">
          <div class="container mt-3">
            <div class="row mt-5">
              <div class="col-xl-6 d-xl-flex">
                <label for="pid" class="col-form-label col-xl-5">เลขประจำตัวประชาชน</label>
                <div class="col-xl-7">
                  <input type="text" id="pid" name="pid" value="{{result().personalId}}" class="form-control-plaintext">
                </div>
              </div>
              <div class="col-xl-6 d-xl-flex">
                <label for="birthDate" class="col-form-label col-xl-5">วันเดือนปีเกิด</label>
                <div class="col-xl-7">
                  <div class="input-group">
                    <input id="birthDate" class="form-control-plaintext"
                           value="{{result().birthDate | thaidatetime:{showTime:false} }}">
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-xl-6 d-xl-flex mb-3">
                <label for="relation" class="col-form-label  col-xl-5">ความสัมพันธ์</label>
                <div class="col-xl-7">
                  <input id="relation" name="relation" class="form-control-plaintext"
                         value="{{result().relation?.name}}">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <hr>
            <div class="mt-5">
              <h6 class="fw-bold">ผลการค้นหา</h6>
            </div>
            <div class="row mt-3">
              <div class="col-xl-6 d-xl-flex">
                <label for="name" class="col-form-label col-xl-5">ชื่อ-นามสกุล</label>
                <div class="col-xl-7">
                  <input type="text" id="name" class="form-control-plaintext"
                         value="{{result().firstName}} {{result().lastName}}">
                </div>
              </div>
              <div class="col-xl-6 d-xl-flex">
                <label for="birthDate" class="col-form-label col-xl-5">วันเดือนปีเกิด</label>
                <div class="col-xl-7">
                  <input id="birthDateResult" class="form-control-plaintext"
                         value="{{result().birthDate | thaiDateFull}}">
                </div>
              </div>
            </div>
            @if (result()?.relation?.id == 26) {
              <div class="card mt-5">
                <div class="container">
                  <div class="row mt-3 mb-3">
                    <div class="col-xl-9">
                       <span class="ui-require fw-bold">
                        เอกสารตามคำสั่งศาล
                      </span>
                    </div>
                    <div class="col-xl-3">
                      <app-file-upload [id]="'file'"
                                       [name]="'file'"
                                       [(ngModel)]="file"
                                       [fileSizeLimit]="5"
                                       [fileSize]="file.fileSize"
                                       #fileUpload="ngModel"
                                       [fileRequired]="true">
                      </app-file-upload>
                      @if (fileUpload.invalid && (fileUpload.dirty || fileUpload.touched)) {
                        <div class="row">
                          <div class="col-12">
                            <custom-error *ngIf="fileUpload.hasError('required')"
                                          error="*กรุณาแนบไฟล์"></custom-error>
                            <custom-error *ngIf="fileUpload.hasError('fileRequired')"
                                          error="*กรุณาแนบไฟล์"></custom-error>
                            <custom-error *ngIf="fileUpload.hasError('fileSizeLimit')"
                                          error="*ขนาดต้องไม่เกิน 5 MB"></custom-error>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
            <div class="row d-flex my-4">
              <div class="col-12 text-center">
                <button class="btn btn-primary" type="button" (click)="saveMember()"><span class="p-5">บันทึก</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      }
    }
    @if (mode == 'UPDATE') {
      <div class="container">
        <div class="row d-flex mt-3">
          <div class="col-10">
            <h5>
              <span class="fw-bold">แก้ไขข้อมูลคนในครอบครัว</span>
            </h5>
          </div>
          <div class="col-2 text-end">
            <button class="btn-close" type="button" aria-label="Close" (click)="close()"></button>
          </div>
        </div>
        <form #form="ngForm">
          <div class="container mt-3">
            <div class="row mt-5">
              <div class="col-xl-6 d-xl-flex">
                <label for="pid2" class="col-form-label col-xl-5">เลขประจำตัวประชาชน</label>
                <div class="col-xl-7">
                  <input type="text" id="pid2" name="pid" value="{{member().memberData.personalId}}"
                         class="form-control-plaintext" readonly>
                </div>
              </div>
              <div class="col-xl-6 d-xl-flex">
                <label for="birthDate2" class="col-form-label col-xl-5">วันเดือนปีเกิด</label>
                <div class="col-xl-7">
                  <div class="input-group">
                    <input id="birthDate2" class="form-control-plaintext"
                           value="{{member().memberData.birthDate | thaidatetime:{showTime:false} }}">
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-xl-6 d-xl-flex mb-3">
                <label for="relation2" class="col-form-label ui-require col-xl-5">ความสัมพันธ์</label>
                <div class="col-xl-7">
                  <select id="relation2" name="searchRelation" class="form-select"
                          [(ngModel)]="member().relation.id" required #relation="ngModel">
                    <option value="">กรุณาระบุ</option>
                    @for (item of relations(); let idx = $index; track item) {
                      <option [value]="item.id">{{ item.name }}</option>
                    }
                  </select>
                  @if (relation.invalid && (relation.dirty || relation.touched)) {
                    <custom-error *ngIf="relation.hasError('required')" error="กรุณาระบุ"></custom-error>
                  }
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <hr>
            <div class="mt-5">
              <h6 class="fw-bold">ผลการค้นหา</h6>
            </div>
            <div class="row mt-3">
              <div class="col-xl-6 d-xl-flex">
                <label for="name2" class="col-form-label col-xl-5">ชื่อ-นามสกุล</label>
                <div class="col-xl-7">
                  <input type="text" id="name2" class="form-control-plaintext"
                         value="{{member().memberData.firstName}} {{member().memberData.lastName}}">
                </div>
              </div>
              <div class="col-xl-6 d-xl-flex">
                <label for="birthDateResult2" class="col-form-label col-xl-5">วันเดือนปีเกิด</label>
                <div class="col-xl-7">
                  <input id="birthDateResult2" class="form-control-plaintext"
                         value="{{member().memberData.birthDate | thaiDateFull}}">
                </div>
              </div>
            </div>
            @if (member().relation?.id == 26) {
              <div class="card mt-5">
                <div class="container">
                  <div class="row mt-3 mb-3">
                    <div class="col-xl-9">
                       <span class="ui-require fw-bold">
                        เอกสารตามคำสั่งศาล
                      </span>
                    </div>
                    <div class="col-xl-3">
                      <app-file-upload [id]="'file'"
                                       [name]="'file'"
                                       [(ngModel)]="file"
                                       [fileSizeLimit]="5"
                                       [fileSize]="file.fileSize"
                                       #fileUpload="ngModel"
                                       [fileRequired]="true">
                      </app-file-upload>
                      @if (fileUpload.invalid && (fileUpload.dirty || fileUpload.touched)) {
                        <div class="row">
                          <div class="col-12">
                            <custom-error *ngIf="fileUpload.hasError('required')"
                                          error="*กรุณาแนบไฟล์"></custom-error>
                            <custom-error *ngIf="fileUpload.hasError('fileRequired')"
                                          error="*กรุณาแนบไฟล์"></custom-error>
                            <custom-error *ngIf="fileUpload.hasError('fileSizeLimit')"
                                          error="*ขนาดต้องไม่เกิน 5 MB"></custom-error>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
            <div class="row d-flex my-4">
              <div class="col-12 text-center">
                <button class="btn btn-primary" type="button" (click)="updateMember()"><span class="p-5">บันทึก</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    }
  </div>
</div>
