import {Component, effect, inject, input, model, Renderer2, signal, ViewChild} from '@angular/core';
import {CustomErrorComponent} from '../../../../shere/commons/custom-error.component';
import {FormsModule, NgForm} from '@angular/forms';
import {NgIf} from '@angular/common';
import {
  SelectMastercupDistrictComponent
} from '../../change-hospital/modal/select-mastercup-district/select-mastercup-district.component';
import {
  SelectMastercupProvinceComponent
} from '../../change-hospital/modal/select-mastercup-province/select-mastercup-province.component';
import {
  SelectMastercupSubdistrictComponent
} from '../../change-hospital/modal/select-mastercup-subdistrict/select-mastercup-subdistrict.component';
import {
  DistrictDto,
  Province,
  ProvinceDto,
  SearchCatmResponse,
  SubDistrict
} from '../../../../../generated-model/model';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {markInvalidControlsAsDirtyAndTouched} from '../../../../app.constants';

@Component({
  selector: 'app-additional-address-info',
  imports: [
    CustomErrorComponent,
    FormsModule,
    NgIf,
    SelectMastercupDistrictComponent,
    SelectMastercupProvinceComponent,
    SelectMastercupSubdistrictComponent
  ],
  templateUrl: './additional-address-info.component.html',
  standalone: true,
  styleUrl: './additional-address-info.component.scss'
})
export class AdditionalAddressInfoComponent {
  searchCatmResponse = signal(undefined as SearchCatmResponse)
  @ViewChild('form', {static: true}) public form: NgForm;
  restrictProvince = input<Province>(undefined);
  selectedSubDistrict = model<SubDistrict>(undefined);
  selectedDistrict = model<DistrictDto>(undefined);
  selectedProvince = model<ProvinceDto>(undefined);
  selectedMoo = model<string>(undefined);
  options = input<{ fetchAll?: boolean, hcode3Level?: boolean }>({fetchAll: false});
  renderer = inject(Renderer2);
  activeModal = inject(NgbActiveModal);
  modalService = inject(NgbModal);

  constructor() {
    effect(() => {
      this.selectedProvince.set(this.searchCatmResponse().province);
    }, {allowSignalWrites: true});
  }

  ngOnInit(): void {
    console.log('options ', this.options())
    if (this.searchCatmResponse()) {
      this.selectedProvince.set(this.searchCatmResponse().province)
      this.selectedDistrict.set(this.searchCatmResponse().district ? this.searchCatmResponse().district : undefined)
    }
  }

  updateFormAndValidity(form: NgForm): boolean {
    if (!form.valid) {
      markInvalidControlsAsDirtyAndTouched(form)
    }
    return form.valid
  }

  submit() {
    let formValid = this.updateFormAndValidity(this.form);
    if (formValid) {
      this.activeModal.close(this.selectedSubDistrict());
    } else {
      console.log('invalid form');
    }
  }

  ngAfterViewInit(): void {
  }

  onProvinceChange() {
    console.log('onProvinceChange')
    this.selectedDistrict.set(undefined);
    this.selectedSubDistrict.set(undefined);
  }

  onDistrictChange() {
    this.selectedSubDistrict.set(undefined);
  }
}
