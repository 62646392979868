<ng-select [id]="id()"
           [name]="name()"
           [items]="subDistrictList$ | async"
           placeholder="กรุณาเลือก"
           bindLabel="name"
           class="novalidate"
           [disabled]="disabled()"
           [clearable]="true"
           [compareWith]="compareWithCode"
           [editableSearchTerm]="true"
           [loading]="typeaheadLoading"
           (ngModelChange)="valueChange($event)"
           [(ngModel)]="value">
</ng-select>
