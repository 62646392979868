import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  District,
  MastercupDto,
  MastercupSearchParams,
  MyPage,
  Province,
  SubDistrict
} from '../../generated-model/model';
import {environment} from '../../environments/environment';
import {IgnoreNullHttpParams} from '../shere/commons/Ignore-null-http-params';

@Injectable({
  providedIn: 'root'
})
export class MastercupResourceService {
  http = inject(HttpClient)

  public searchMasterCup(params: MastercupSearchParams) {
    return this.http.get<MyPage<MastercupDto>>(`${environment.serverUrl}/api/secure/mastercup`, {
      params: IgnoreNullHttpParams.fromObject(params).toHttpParam()
    })
  }

  findProvinceAll() {
    return this.http.get<Province[]>(`${environment.serverUrl}/api/secure/all-province`)
  }

  findDistrictAllByProvinceId(provinceId: string) {
    return this.http.get<District[]>(`${environment.serverUrl}/api/secure/district-by-province/${provinceId}`)
  }

  findSubDistrictAllByDistrictId(districtId: string) {
    return this.http.get<SubDistrict[]>(`${environment.serverUrl}/api/secure/sub-district-by-district/${districtId}`)
  }

  findMooBySubDistrictId(subDistrictId: string) {
    //todo
    return undefined;
  }
}

