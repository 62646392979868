import {Component, inject, Input, input, OnInit, signal, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbPagination} from '@ng-bootstrap/ng-bootstrap';
import {SlicePipe} from '@angular/common';
import {FormDisabledDirective} from '../../../../../shere/commons/form-disabled.directive';
import {FormsModule, NgForm} from '@angular/forms';
import {NotFoundComponent} from '../../../../../shere/not-found/not-found.component';
import {MastercupDto, MastercupSearchParams, MyPage, SubDistrictDto} from '../../../../../../generated-model/model';
import {MastercupResourceService} from '../../../../../service/mastercup-resource.service';
import {BKK_PROVINCE_ID, SN, SP} from '../../../../../app.constants';


@Component({
  selector: 'app-select-mastercup',
  imports: [
    FormDisabledDirective,
    FormsModule,
    NgbPagination,
    SlicePipe,
    NotFoundComponent
  ],
  templateUrl: './select-mastercup.component.html',
  standalone: true,
  styleUrl: './select-mastercup.component.scss'
})
export class SelectMastercupComponent implements OnInit {
  @ViewChild('form', {static: true}) public form: NgForm;
  @Input('subDistrict') public subDistrict: SubDistrictDto;

  activeModal = inject(NgbActiveModal)
  mastercupResource = inject(MastercupResourceService)
  catmNear = input<string>();
  pageData: MyPage<MastercupDto> = {size: 10, number: 0};
  params = signal({} as MastercupSearchParams)

  ngOnInit() {
    this.search()
  }


  provinceBkk(subDistrict: SubDistrictDto) {
    let provinceId = subDistrict?.district?.province?.id;
    if (!provinceId) {
      return false;
    }
    return provinceId == BKK_PROVINCE_ID || provinceId.startsWith(SN) || provinceId.startsWith(SP);
  }

  select(mastercup: MastercupDto) {
    this.activeModal.close(mastercup);
  }

  search(page: number = 1) {
    this.params().catm = this.subDistrict.catm
    this.params().page = page - 1
    this.params().size = this.pageData.size
    this.mastercupResource.searchMasterCup(this.params()).subscribe(e => {
      this.pageData = e
    })
  }
}
