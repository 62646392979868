import {inject, Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SearchCatmResponse} from '../../../../../generated-model/model';
import {from} from 'rxjs';
import {AdditionalAddressInfoComponent} from './additional-address-info.component';

@Injectable({
  providedIn: 'root'
})
export class AdditionalAddressInfoService {
  private modalService = inject(NgbModal)

  constructor() {
  }

  open(searchCatmResponse: SearchCatmResponse) {
    const modalRef = this.modalService.open(AdditionalAddressInfoComponent, {
      size: "lg",
      windowClass: 'custom-modal-content',
      backdrop: 'static',
      keyboard: false
    });
    const component: AdditionalAddressInfoComponent = modalRef.componentInstance;
    component.searchCatmResponse.set(searchCatmResponse)
    return from(modalRef.result)
  }
}
