import {
  AfterViewInit,
  Component,
  effect,
  inject,
  input,
  model,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, NgForm} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {NgSelectModule} from "@ng-select/ng-select";
import {DigitOnlyModule} from "@uiowa/digit-only";
import {SelectMastercupProvinceComponent} from "../select-mastercup-province/select-mastercup-province.component";
import {SelectMastercupDistrictComponent} from "../select-mastercup-district/select-mastercup-district.component";
import {
  SelectMastercupSubdistrictComponent
} from "../select-mastercup-subdistrict/select-mastercup-subdistrict.component";
import {DistrictDto, Province, ProvinceDto, SubDistrict, SubDistrictDto} from '../../../../../../generated-model/model';
import {SharedCommonModule} from '../../../../../shere/commons/shared-common.module';
import {BKK_PROVINCE_ID, markInvalidControlsAsDirtyAndTouched, SN, SP} from '../../../../../app.constants';
import {CustomErrorComponent} from '../../../../../shere/commons/custom-error.component';


export type MastercupCatm = {
  province?: ProvinceDto,
  district?: DistrictDto,
  subDistrict?: SubDistrictDto,
  moo?: string,
};

@Component({
  selector: 'app-select-mastercup-catm',
  templateUrl: './select-mastercup-catm.component.html',
  standalone: true,
  styles: [
    `
      .modal-body {
        //min-height: 300px;
      }
    `
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    DigitOnlyModule,
    CustomErrorComponent,
    FormsModule,
    SharedCommonModule,
    SelectMastercupProvinceComponent,
    SelectMastercupDistrictComponent,
    SelectMastercupSubdistrictComponent,
  ]
})
export class SelectMastercupCatmComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('form', {static: true}) public form: NgForm;
  restrictProvince = input<Province>(undefined);
  selectedSubDistrict = model<SubDistrict>(undefined);
  selectedDistrict = model<DistrictDto>(undefined);
  selectedProvince = model<ProvinceDto>(undefined);
  selectedMoo = model<string>(undefined);
  options = input<{ fetchAll?: boolean, hcode3Level?: boolean }>({fetchAll: false});
  renderer = inject(Renderer2);
  activeModal = inject(NgbActiveModal);
  modalService = inject(NgbModal);

  constructor() {
    effect(() => {
      this.selectedProvince.set(this.restrictProvince());
    }, {allowSignalWrites: true});
  }

  ngOnInit(): void {
    console.log('options ', this.options())
  }

  updateFormAndValidity(form: NgForm): boolean {
    if (!form.valid) {
      markInvalidControlsAsDirtyAndTouched(form)
    }
    return form.valid
  }

  submit() {
    let formValid = this.updateFormAndValidity(this.form);
    if (formValid) {
      let masterCupCatm: MastercupCatm = {};
      masterCupCatm.province = this.selectedProvince();
      masterCupCatm.district = this.selectedDistrict();
      masterCupCatm.subDistrict = this.selectedSubDistrict();
      masterCupCatm.moo = this.selectedMoo();
      this.activeModal.close(masterCupCatm);
    } else {
      console.log('invalid form');
    }
  }

  ngAfterViewInit(): void {
  }

  onProvinceChange() {
    this.selectedDistrict.set(undefined);
    this.selectedSubDistrict.set(undefined);
  }

  onDistrictChange() {
    this.selectedSubDistrict.set(undefined);
  }

  provinceBkk() {
    let provinceId = this.selectedProvince()?.id;
    if (!provinceId) {
      return false;
    }
    return provinceId == BKK_PROVINCE_ID || provinceId.startsWith(SN) || provinceId.startsWith(SP);
  }

  ngOnDestroy(): void {
  }
}
