<div class="text-start mb-3">
  <h6 class="text-secondary fw-bold">ตรวจสอบสิทธิบุคคลในครอบครัว</h6>
</div>
<div class="row d-flex mb-3">
  <div class="col-12 text-end">
    <button class="btn btn-green fw-bold" (click)="openAddFamilyModal()">
      <i class="bi bi-plus"></i> เพิ่มคนในครอบครัว
    </button>
  </div>
</div>
<div class="container-fluid">
  @for (member of allFamilyMembers(); let idx = $index; track member) {
    <div class="card mt-2 shadow" (click)="select(member)">
      <div class="card-body">
        <div class="row d-flex py-xl-3">
          <div class="col-xl-2 mb-3">
            <div class="d-flex justify-content-end ">
              <button type="button"
                      class="btn btn-warning text-center" (click)="edit(member);$event.stopPropagation()">
                <i class="bi bi-pencil-fill text-white"></i>
              </button>
              <button type="button"
                      class="btn btn-red text-center ms-1"
                      (click)="deleteMember(member?.memberData?.personalId);$event.stopPropagation()">
                <i class="bi bi-trash text-white"></i>
              </button>
            </div>
          </div>
          <div class="col-xl-4 d-flex mb-3">
            <div class="col-5 text-xl-center">ชื่อ-นามสกุล:</div>
            <div class="col-7">{{ member?.memberData?.firstName }} {{ member?.memberData?.lastName }}</div>
          </div>
          <div class="col-xl-4 row d-flex mb-3">
            <div class="col-5 text-xl-center">ความสัมพันธ์:</div>
            <div class="col-7">{{ member?.relation?.name }}</div>
          </div>
          <!--      <div class="col-xl-2 text-center">
                  <button class="btn btn-primary px-5" (click)="select(member)">เลือก</button>
                </div>-->
        </div>
      </div>
    </div>
  }
</div>

