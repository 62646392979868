<div class="row mb-3">
  <label for="mainInscl" class="col-xl-6 col-form-label text-xl-end">สิทธิที่ใช้เบิก</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="mainInscl" formDisabled
           [ngModel]="fund.subInsclDesc || '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="startDateTime" class="col-xl-6 col-form-label text-xl-end">วันที่เริ่มสิทธิ</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="startDateTime"
           formDisabled [ngModel]="(fund?.startDate | thaidatetime:{month:'long',showTime:false}) || '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="expireDateTime" class="col-xl-6 col-form-label text-xl-end">วันที่หมดสิทธิ</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="expireDateTime"
           formDisabled [ngModel]="(fund?.expireDate | thaidatetime:{month:'long',showTime:false}) || '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="hmainOp" class="col-xl-6 col-form-label text-xl-end">รพ. รักษา</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="hmainOp"
           formDisabled [ngModel]="fund?.hospMainOpName || '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="sssProvince" class="col-xl-6 col-form-label text-xl-end">จังหวัดที่ลงทะเบียนรักษา</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="sssProvince"
           formDisabled [ngModel]="fund?.provinceName || '-'">
  </div>
</div>
