import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  standalone: true,
  name: 'thaiDateFull'
})
export class ThaiDateFullPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value) {
      if ((typeof value) === 'string') {
        let part = value.split(/[^0-9]/);
        if (part.length == 6) {
          value = new Date(part[0], part[1] - 1, part[2], part[3], part[4], part[5]);
        } else if (part.length == 7) {
          value = new Date(part[0], part[1] - 1, part[2], part[3], part[4], part[5], part[6]);
          // value = new Date(value)
        } else {
          value = new Date(part[0], part[1] - 1, part[2]);
        }
      }
      return value.toLocaleDateString('th-TH', {day:'numeric',month: 'long', year: 'numeric'});
    } else {
      return "";
    }
  }

}
