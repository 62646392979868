import {Component, inject, signal} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {checkScreenSize, customIcon, locateOptions, options} from '../../../../app.constants';
import {LeafletModule} from '@bluehalo/ngx-leaflet';
import {
  ReverseAddressRequest,
  ReverseAddressResponse,
  SearchAddressRequest,
  SearchCatmRequest
} from '../../../../../generated-model/model';
import {control, latLng, LeafletMouseEvent, Map, marker} from 'leaflet';
import {environment} from '../../../../../environments/environment';
import {NgxLeafletLocateModule} from '@runette/ngx-leaflet-locate';
import {AddressResource} from '../../../../service/address-resource.service';
import {ConfirmDialogService} from '../../../../shere/commons/dialog/confirm-dialog/confirm-dialog.service';
import {AdditionalAddressInfoService} from '../additional-address-info/additional-address-info.service';

@Component({
  selector: 'app-pinned-address',
  imports: [
    FormsModule,
    LeafletModule,
    NgxLeafletLocateModule,
  ],
  templateUrl: './pinned-address.component.html',
  standalone: true,
  styleUrl: './pinned-address.component.scss'
})
export class PinnedAddressComponent {
  map: Map;
  layers: any = []
  options = options;
  activeModal = inject(NgbActiveModal);
  addressResource = inject(AddressResource)
  additionalAddressInfoService = inject(AdditionalAddressInfoService)
  confirmDialogService = inject(ConfirmDialogService)
  searchRequest = signal({} as SearchAddressRequest)
  reverseRequest = signal({} as ReverseAddressRequest)
  searchCatmRequest = signal({} as SearchCatmRequest)

  ngOnInit(): void {
    this.searchCatmRequest.set({})
    if (!environment.production) {
      window.addEventListener('load', checkScreenSize);
      window.addEventListener('resize', checkScreenSize);
    }
  }

  onMapReady(map: Map) {
    this.map = map;
    this.checkPermissionAndAddLocateControl()
  }

  checkPermissionAndAddLocateControl() {
    const locateControl = control.locate(locateOptions);
    navigator.permissions
      .query({name: 'geolocation'})
      .then((permissionStatus) => {
        if (permissionStatus.state === 'granted') {
          // Permission is granted, start locating
          locateControl.addTo(this.map).start();
        } else if (permissionStatus.state === 'prompt') {
          // Permission is not yet granted, ask for permission
          navigator.geolocation.getCurrentPosition(
            (position) => {
              locateControl.addTo(this.map).start();
            },
            (error) => {
              console.error('Error getting current position:', error);
            }
          );
        } else {
          locateControl.addTo(this.map)
          console.error('Geolocation permission denied.');
        }
      })
      .catch((error) => {
        console.error('Error checking geolocation permission:', error);
      });
  }

  search() {
    this.addressResource.searchAddress(this.searchRequest()).subscribe({
      next: response => {
        if (response?.length > 0) {
          this.map?.setView(latLng(Number(response[0].lat), Number(response[0].lon)), 18, {animate: true, duration: 1});
          let markers = marker([Number(response[0].lat), Number(response[0].lon)], {
            title: `${response[0].name}`,
            icon: customIcon()
          });
          this.layers = [markers]
        }
      }
    })
  }

  onClickMap($event: LeafletMouseEvent) {
    this.reverseRequest().lat = $event.latlng.lat
    this.reverseRequest().lon = $event.latlng.lng
    this.addressResource.reverseAddress(this.reverseRequest()).subscribe({
      next: value => {
        this.confirmDialogService.open({title: `ยืนยันการเลือกที่อยู่`, content: value.display_name}).subscribe(e => {
          console.log(value)
          this.handleAddress(value)
        })
      }
    })
  }

  handleAddress(addressResponse: ReverseAddressResponse) {
    let address = addressResponse.address
    if (address.province) {
      this.searchCatmRequest().provinceName = address.province.replace('จังหวัด', '')

      if (!address.county && address.town) {
        this.searchCatmRequest().districtName = address.town.replace('อำเภอ', '')
      } else if (address.county) {
        this.searchCatmRequest().districtName = address.county.replace('อำเภอ', '')
      }

      if (address.city_district) {
        this.searchCatmRequest().subDistrictName = address.city_district.replace('ตำบล', '')
      } else if (address.city) {
        this.searchCatmRequest().subDistrictName = address.city.replace('ตำบล', '').replace('เทศบาลตำบล', '')
      }
    } else if (address.city.startsWith('กรุงเทพ')) {
      this.searchCatmRequest().provinceName = 'กรุงเทพ'
      this.searchCatmRequest().districtName = address.suburb ? address.suburb : undefined
      this.searchCatmRequest().subDistrictName = address.quarter ? address.quarter.replace('แขวง', '') : undefined
    }
    this.convertAddressToCatm()
  }

  convertAddressToCatm() {
    this.addressResource.toCatm(this.searchCatmRequest()).subscribe({
      next: response => {
        if (response == null || response.catm == null || response.subDistrict == null || response.district == null) {
          this.additionalAddressInfoService.open(response).subscribe({
            next:value => {
              response.subDistrict = value
              this.activeModal.close(response.subDistrict)
            }
          })
        } else {
          this.activeModal.close(response.subDistrict)
        }
      }
    })
  }
}
