<header id="header" class="navbar sticky-top flex-md-nowrap p-0 color-header">
  <a class="navbar-brand d-none d-md-flex me-0 px-2" href="#">
    <img src="./assets/images/nhso-logo.jpg" alt="Logo" height="35" width="35">
    <b class="text-white">ระบบตรวจสอบสิทธิ และลงทะเบียนภาคประชาชน</b>
  </a>
</header>
<div id="content">
  <div class="ps-3 pt-3 pe-3 pb-0">
    <div class="container">
      <div class="card custom-box">
        <div class="card-body">
          <div class="mt-3"><span class="fw-bold text-primary">ข้อมูลประชากร ของ</span>
            <span class="fw-bold text-warning"> สปสช.</span>
          </div>
          <div class="container">
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 d-xl-flex">
                <label for="pid" class="col-form-label col-xl-4">เลขประจำตัวประชาชน:</label>
                <div class="col-xl-8">
                  <input type="text" id="pid" class="form-control-plaintext" [value]="data()?.personalId" readonly>
                </div>
              </div>
              <div class="col-xl-6 d-xl-flex">
                <label for="ิbirthdate" class="col-form-label col-xl-4">เดือนปีเกิด:</label>
                <div class="col-xl-8">
                  <input type="text" id="ิbirthdate" class="form-control-plaintext"
                         [value]="data()?.birthDate | birthDateWithoutDate"
                         readonly>
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 d-xl-flex">
                <label for="name" class="col-form-label col-xl-4">ชื่อ-สกุล:</label>
                <div class="col-xl-8">
                  <input type="text" id="name" class="form-control-plaintext"
                         value="{{data()?.firstName}} {{data()?.lastName}}" readonly>
                </div>
              </div>
              <div class="col-xl-6 d-xl-flex">
                <label for="sex" class="col-form-label col-xl-4">เพศ:</label>
                <div class="col-xl-8">
                  <input type="text" id="sex" class="form-control-plaintext" [value]="data()?.sexDesc" readonly>
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 d-xl-flex">
                @if (data()?.statusDola == '1') {
                  <label for="statusDola1" class="col-form-label col-xl-4">สภาพบุคคล:</label>
                  <div class="col-xl-8">
                    <input type="text" id="statusDola1" class="form-control-plaintext text-danger"
                           [value]="data()?.statusDolaDesc" style="color: red !important;"
                           readonly>
                  </div>
                } @else {
                  <label for="statusDola2" class="col-form-label col-xl-4">สภาพบุคคล:</label>
                  <div class="col-xl-8">
                    <input type="text" id="statusDola2" class="form-control-plaintext" [value]="data()?.statusDolaDesc"
                           readonly>
                  </div>
                }
              </div>
              <div class="col-xl-6 d-xl-flex">
                <label for="dataDate" class="col-form-label col-xl-4">ข้อมูล ณ วันที่:</label>
                <div class="col-xl-8">
                  <input type="text" id="dataDate" class="form-control-plaintext"
                         [value]="data()?.checkDate | thaidatetime" readonly>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid my-5">
          <div class="fs-6 mb-3"><span class="px-4 py-2 text-white rounded-top-3" style="background-color: #8daff7">ข้อมูลตรวจสอบสิทธิ ณ ปัจจุบัน</span>
          </div>
          <div class="card-body border rounded">
            @for (fund of data()?.fundList; let inx = $index; track fund) {
              <div class="fw-bold text-primary">สิทธิใช้เบิก</div>
              <div class="mt-3">
                @if (fund.mainInscl == 'UCS' || fund.mainInscl == 'WEL' || !fund.mainInscl) {
                  <app-uc-detail [fund]="fund"></app-uc-detail>
                } @else if (fund.mainInscl == 'SSS') {
                  <app-sss-detail [fund]="fund"></app-sss-detail>
                } @else if (fund.mainInscl == 'PVT') {
                  <app-pvt-detail [fund]="fund"></app-pvt-detail>
                } @else if (fund.mainInscl == 'WVO') {
                  <app-wvo-detail [fund]="fund"></app-wvo-detail>
                } @else if (fund.mainInscl == 'VET') {
                  <app-vet-detail [fund]="fund"></app-vet-detail>
                } @else if (fund.mainInscl == 'DIS') {
                  <app-dis-detail [fund]="fund"></app-dis-detail>
                } @else if (fund.mainInscl == 'NRD' || fund.mainInscl == 'NRU' || fund.mainInscl == 'FRG') {
                  <app-nrd-nru-frg-detail [fund]="fund"></app-nrd-nru-frg-detail>
                } @else if (fund.mainInscl == 'NRH') {
                  <app-nrh-detail [fund]="fund"></app-nrh-detail>
                } @else if (fund.mainInscl == 'ST1' || fund.mainInscl == 'ST2' || fund.mainInscl == 'STP') {
                  <app-st1-st2-stp-detail [fund]="fund"></app-st1-st2-stp-detail>
                  <!--            todo NRH-->
                } @else if (fund.mainInscl == 'OFC' || fund.mainInscl == 'LGO' || fund.mainInscl == 'SSI' || fund.mainInscl == 'BFC') {
                  <app-ofc-lgo-ssi-bfc-detail [fund]="fund"></app-ofc-lgo-ssi-bfc-detail>
                }
              </div>
            }
          </div>
        </div>
      </div>
      <div class="text-center my-3">
        <a href="/" class="btn btn-primary">
          <span class="px-5 fw-bold">ย้อนกลับ</span>
        </a>
      </div>
    </div>
  </div>
</div>
