import {Component, forwardRef, inject, Input, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from "@ng-select/ng-select";
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from "@angular/forms";
import {BuildingType} from "../../../../generated-model/model";
import {SharedCommonModule} from "../../commons/shared-common.module";
import {BuildingTypeResourceService} from '../../../service/building-type-resource.service';

@Component({
  selector: 'app-select-building-type',
  standalone: true,
  imports: [CommonModule, NgSelectModule, SharedCommonModule, FormsModule],
  templateUrl: './select-building-type.component.html',
  styleUrls: ['./select-building-type.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectBuildingTypeComponent)
    }
  ]
})
export class SelectBuildingTypeComponent implements ControlValueAccessor {
  id = input<string>('buildingType');
  name = input<string>('buildingType');
  @Input() value: BuildingType;
  disabled = input(false);
  items: BuildingType[] = [];
  touched = false;
  private buildingTypeResource = inject(BuildingTypeResourceService);

  constructor() {
    this.buildingTypeResource.getAllBuildingType()
      .subscribe(items => this.items = items);
  }

  onChange = (value) => {
  };
  onTouched = () => {
  };

  writeValue(value: BuildingType) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  onValueChange(value: BuildingType) {
    this.onChange(value);
    this.markAsTouched();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
