import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  ReverseAddressRequest,
  ReverseAddressResponse,
  SearchAddressRequest,
  SearchAddressResponse,
  SearchCatmRequest,
  SearchCatmResponse
} from '../../generated-model/model';
import {environment} from '../../environments/environment';
import {IgnoreNullHttpParams} from '../shere/commons/Ignore-null-http-params';

@Injectable({
  providedIn: 'root'
})
export class AddressResource {
  http = inject(HttpClient)

  searchAddress(request: SearchAddressRequest) {
    return this.http.get<SearchAddressResponse[]>(`${environment.serverUrl}/api/secure/address/search`,
      {params: IgnoreNullHttpParams.fromObject(request).toHttpParam()})
  }

  reverseAddress(request: ReverseAddressRequest) {
    return this.http.get<ReverseAddressResponse>(`${environment.serverUrl}/api/secure/address/reverse`,
      {params: IgnoreNullHttpParams.fromObject(request).toHttpParam()})
  }

  toCatm(request:SearchCatmRequest){
    return this.http.get<SearchCatmResponse>(`${environment.serverUrl}/api/secure/address/to-catm`,
      {params: IgnoreNullHttpParams.fromObject(request).toHttpParam()})
  }
}
