import {Component, effect, inject, OnInit, signal} from '@angular/core';
import {RightCheckComponent} from '../right-check/right-check.component';
import {Router, RouterLink} from '@angular/router';
import {RelationshipRightsMember, RightsDto, Sex} from '../../../../generated-model/model';
import {FormsModule} from '@angular/forms';
import {RightCheckResource} from '../../../service/right-check-resource.service';
import {RegisterPanelComponent} from '../../register-panel/register-panel.component';
import {FamilyRightCheckDetailComponent} from './family-right-check-detail/family-right-check-detail.component';
import {RegistrationUtils} from '../../../shere/commons/utils/registration-utils';

@Component({
  selector: 'app-family-right-check',
  imports: [
    RightCheckComponent,
    FormsModule,
    RouterLink,
    RegisterPanelComponent,
    FamilyRightCheckDetailComponent
  ],
  templateUrl: './family-right-check.component.html',
  standalone: true,
  styleUrl: './family-right-check.component.scss'
})
export class FamilyRightCheckComponent implements OnInit {
  protected readonly Sex = Sex;
  protected readonly RegistrationUtils = RegistrationUtils;

  router = inject(Router)
  rightCheckResource = inject(RightCheckResource)

  data = signal({} as RightsDto)
  member = signal(undefined as RelationshipRightsMember)
  canBeRegistered = signal<boolean>(false);
  activeId = signal<number>(1);

  constructor() {
    this.member.set(this.router.getCurrentNavigation() ? this.router.getCurrentNavigation()?.extras.state : this.router.lastSuccessfulNavigation?.extras.state)
    this.rightCheckResource.searchRight(this.member()?.memberData.personalId).subscribe({
      next: value => {
        this.data.set(value)
          this.canBeRegistered.set(
            this.activeId() == 1
            && RegistrationUtils.showRegisterPanel(this.data(), this.data()?.fundList?.[0])
          );
      }, error: err => {
        if (err?.status == 400) {
          this.data.set(undefined);
          console.log(err)
        }
      }
    })
  }

  ngOnInit(): void {
  }
}
