<div class="row">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-striped table-bordered caption-top">
        <caption>กรมธรรม์สิทธิประโยชน์</caption>
        <thead>
        <tr>
          <th nowrap scope="col">ลำดับ</th>
          <th nowrap scope="col">รหัสกลุ่ม</th>
          <th nowrap scope="col">ชื่อกลุ่ม</th>
          <th nowrap scope="col">รหัสบริการ</th>
          <th nowrap scope="col">ชื่อบริการ</th>
          <th nowrap scope="col">Package Target Group</th>
          <th nowrap scope="col">Package Type</th>
          <th nowrap scope="col">ประเภทบริการ</th>
          <th nowrap scope="col">สถานะบริการ</th>
          <th nowrap scope="col">วันที่เริ่มให้บริการ</th>
          <th nowrap scope="col">วันที่สิ้นสุดบริการ</th>
        </tr>
        </thead>
        <tbody>
          @for (privilege of phrServices() | slice :(page - 1) * pageSize : page * pageSize; let i = $index; track (privilege.service_id + privilege.class_id)) {
            <tr>
              <td class="text-center">{{ ((page - 1) * pageSize) + (i + 1) }}</td>
              <td>{{ privilege.class_id }}</td>
              <td nowrap="">{{ privilege.class_name }}</td>
              <td>{{ privilege.service_id }}</td>
              <td>{{ privilege.service_name }}</td>
              <td>{{ privilege.package_target_group }}</td>
              <td>{{ privilege.package_type }}</td>
              <td>{{ privilege.service_type }}</td>
              <td>{{ privilege.service_status }}</td>
              <td class="col-date">{{privilege.start_date}}</td>
              <td class="col-date">{{privilege.end_date || '-'}}</td>
            </tr>
          } @empty {
            <tr>
              <td colspan="11">
                <app-not-found></app-not-found>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <div class="row justify-content-between">
      <div class="col-xl-6 text-muted">
        (รายการที่พบทั้งหมด {{ phrServices()?.length || 0 }} รายการ , หน้า : {{ page }}
        / {{ totalPage || 0 }})
      </div>
      <div class="col-xl-6">
        <ngb-pagination class="d-flex justify-content-end"
                        [(page)]="page"
                        [pageSize]="pageSize"
                        [boundaryLinks]="true"
                        [collectionSize]="phrServices()?.length"/>
      </div>
    </div>
  </div>
</div>
