import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  effect,
  HostListener,
  inject,
  OnChanges,
  Renderer2,
  signal,
  SimpleChanges
} from '@angular/core';
import {Router, RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {UserDataService} from '../../service/user-data.service';
import {LoadingComponent} from '../../shere/commons/loader/loading.component';
import {NgbdToastGlobalModule} from '../../shere/commons/toast/toast-global.module';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    FormsModule,
    RouterLink,
    RouterOutlet,
    RouterLinkActive,
    LoadingComponent,
    NgbdToastGlobalModule
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements AfterViewInit, AfterViewChecked, OnChanges {
  private router = inject(Router);
  renderer = inject(Renderer2);
  toggleSidebar = signal(true);
  selectedFontSize: '14px' | '16px' | '18px' = '14px';
  rightSearchCollapsed = false;
  userDataService = inject(UserDataService)
  userFullName = signal('')

  constructor() {
    effect(() => {
      console.log(`toggleSidebar is : ${this.toggleSidebar()}`);
      this.calculateLayoutRatio();
    });
  }

  ngOnInit(): void {
    this.userDataService.getCurrent().subscribe({
      next: value => {
        this.userFullName.set(value.fullName)
      }, error: err => {
        console.log(err)
      }
    })
  }

  ngAfterViewInit(): void {
    this.calculateLayoutRatio();
    setTimeout(() => {
      this.checkActiveDropdownMenu();
    });
  }

  ngAfterViewChecked(): void {
    // this.checkActiveDropdownMenu();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // 1024px is break point of large devices
    console.log('window:resize');
    this.toggleSidebar.set(event.target.innerWidth < 1024);
    this.calculateLayoutRatio();
  }

  calculateLayoutRatio() {
    // let sidebar = document.getElementById("sidebar");
    // let content = document.getElementById("content");
    // if (this.toggleSidebar()) {
    //   content.style.width = `calc(100% - 300px);`;
    // } else {
    //   content.style.width = '100%;'
    // }
  }

  toHome() {
    this.router.navigate(["/home"])
  }

  sidebarToggle() {
    this.toggleSidebar.set(!this.toggleSidebar());
  }

  setFontSizeTo(fontSize: string) {
    this.renderer.setAttribute(document.querySelector('body'), 'data-default-font-size', fontSize);
    document.querySelectorAll('[id^=defaultFontSize]')
      .forEach((value, key) => {
        if (value.id.endsWith(fontSize)) {
          this.renderer.addClass(value, 'active');
        } else {
          this.renderer.removeClass(value, 'active');
        }
      });
  }

  private checkActiveDropdownMenu() {
    /*    let list = document.querySelectorAll('[data-bs-toggle="collapse"]');
        list.forEach((toggler, key) => {
          let dataBsTarget = toggler.getAttribute('data-bs-target');
          let collapsedElement = document.querySelector(`${dataBsTarget}`);
          let childrenActiveList = document.querySelectorAll(`${dataBsTarget} a.active`);
          if (childrenActiveList.length) {
            // toggler.classList.add('active');
            toggler.classList.add('collapsed');
            collapsedElement.classList.add('show');
            toggler.setAttribute('aria-expanded', 'true');
          } else {
            // toggler.classList.remove('active');
            toggler.classList.remove('collapsed');
            collapsedElement.classList.remove('show');
            toggler.setAttribute('aria-expanded', 'false');
          }
        });*/
  }
}
