import {Component, inject, OnInit, signal} from '@angular/core';
import {UserService} from 'angular-auth-oidc-client/lib/user-data/user.service';
import {UserDataService} from '../../service/user-data.service';
import {MyPage, RegMainCitizenDto, RegMainCitizenParams, UserData} from '../../../generated-model/model';
import {FormsModule} from '@angular/forms';
import {RegMainCitizenResourceService} from '../../service/reg-main-citizen-resource.service';
import {NgbPagination} from '@ng-bootstrap/ng-bootstrap';
import {ThaiDateTimePipe} from '../../shere/commons/thai-datetime.pipe';

@Component({
  selector: 'app-history',
  imports: [
    FormsModule,
    NgbPagination,
    ThaiDateTimePipe
  ],
  templateUrl: './history.component.html',
  standalone: true,
  styleUrl: './history.component.scss'
})
export class HistoryComponent implements OnInit {
  userDataService = inject(UserDataService);
  regMainCitizenResource =inject(RegMainCitizenResourceService)
  userData = signal({} as UserData)
  pageData:MyPage<RegMainCitizenDto>={size:10,number:0}
  params=signal({} as RegMainCitizenParams)

  ngOnInit() {
    this.userDataService.getCurrent().subscribe(value => {
      this.userData.set(value);
      console.log(this.userData());
      this.search();
    });
  }

  search(page: number = 1){
    this.params().page = page - 1
    this.params().size = this.pageData.size
    this.regMainCitizenResource.search(this.params()).subscribe(value => {
      this.pageData=value;
      console.log(this.pageData);
    })
  }

  get personalId(): string {
    return this.params().personalId || '';
  }

  set personalId(value: string) {
    this.params.update(params => ({...params, personalId: value}));
  }
}
