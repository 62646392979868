import {Component, effect, inject, signal} from '@angular/core';
import {RightCheckComponent} from '../right-check/right-check.component';
import {Router} from '@angular/router';
import {RightsDto, RightsHistoryDto, UserData} from '../../../../generated-model/model';
import {RightCheckResource} from '../../../service/right-check-resource.service';
import {UserDataService} from '../../../service/user-data.service';
import {RegisterPanelComponent} from '../../register-panel/register-panel.component';
import {SelfRightCheckDetailComponent} from './self-right-check-detail/self-right-check-detail.component';
import {RelationshipRightsMemberService} from '../../../service/relationship-rights-member.service';
import {RegistrationUtils} from '../../../shere/commons/utils/registration-utils';

@Component({
  selector: 'app-self-right-check',
  imports: [RightCheckComponent, RegisterPanelComponent, SelfRightCheckDetailComponent],
  templateUrl: './self-right-check.component.html',
  standalone: true,
  styleUrl: './self-right-check.component.scss'
})
export class SelfRightCheckComponent {
  router: Router = inject(Router)
  userDataService = inject(UserDataService)
  rightCheckResource = inject(RightCheckResource)
  relationRightMemberResourceService = inject(RelationshipRightsMemberService)

  userData = signal({} as UserData)
  data = signal({} as RightsDto)
  histories = signal([] as RightsHistoryDto[])
  canBeRegistered = signal<boolean>(false);
  activeId = signal<number>(1);

  constructor() {
    effect(() => {

    }, {allowSignalWrites: true});
  }

  ngOnInit() {
    this.userDataService.getCurrent().subscribe({
      next: value => {
        this.userData.set(value)
        this.rightCheckResource.searchRight(this.userData().pid).subscribe({
            next: data => {
              this.data.set(data)
              this.canBeRegistered.set(
                this.activeId() == 1
                && RegistrationUtils.showRegisterPanel(this.data(), this.data()?.fundList?.[0])
              );
            }, error: err => {
              if (err?.status == 400) {
                this.data.set(undefined);
                console.log(err)
              }
            }
          }
        )
      }
    })
  }
}
