import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {IgnoreNullHttpParams} from '../shere/commons/Ignore-null-http-params';
import {AttachmentRule, AttachmentsRulesSearchDto} from '../../generated-model/model';

@Injectable({
  providedIn: 'root'
})
export class AttachmentResource {
  http = inject(HttpClient)

  searchRules(request: AttachmentsRulesSearchDto){
    let httpParams = IgnoreNullHttpParams.fromObject({
      ...request
    }).toHttpParam();
    return this.http.get<AttachmentRule[]>(`${environment.serverUrl}/api/secure/search-attachment-rules`, {params: httpParams});  }
}
