import {inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Service} from '../../generated-model/model';

@Injectable({
  providedIn: 'root'
})
export class PhrBenefitResource {
  http = inject(HttpClient)

  public getBenefit(pid: string) {
    return this.http.get<Service[]>(`${environment.serverUrl}/api/secure/phr-benefit/${pid}`)
  }
}
