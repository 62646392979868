/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.29.814 on 2025-01-26 15:14:27.

export interface Page<T> extends Slice<T> {
    totalPages?: number;
    totalElements?: number;
}

export interface Slice<T> extends Streamable<T> {
    first?: boolean;
    pageable?: Pageable;
    numberOfElements?: number;
    size?: number;
    content?: T[];
    number?: number;
    sort?: any;
    last?: boolean;
}

export interface AttachmentGroup {
    group?: string;
    name?: string;
    required?: number;
}

export interface AttachmentType {
    id?: number;
    name?: string;
    group?: string;
    attachmentGroup?: AttachmentGroup;
}

export interface BuildingType extends PanacheEntityBase {
    id?: number;
    name?: string;
    used?: YesNo;
}

export interface Catm {
    catm?: string;
    name?: string;
    changwatName?: string;
    amphurName?: string;
    tumbonName?: string;
    moobanName?: string;
    moo?: string;
}

export interface CurrentHomeAddress {
    adressNo?: string;
    trok?: string;
    soi?: string;
    road?: string;
    postCode?: string;
}

export interface District {
    code?: string;
    name?: string;
    province?: Province;
}

export interface Nation {
    id?: number;
    code?: string;
    name?: string;
    codeEn?: string;
    nameEn?: string;
}

export interface PersonalData extends PanacheEntityBase {
    personalId?: string;
    transId?: number;
    subPersonalId?: string;
    titleNameId?: string;
    titleName?: TitleName;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    motherId?: string;
    fatherId?: string;
    nationCode?: string;
    nation?: Nation;
    birthDate?: string;
    sex?: Sex;
    homeAddress?: PersonalHomeAddress;
    currentAddress?: CurrentHomeAddress;
    transDate?: any;
    statusDola?: string;
    deathDate?: any;
    disability?: boolean;
    bora?: string;
    createDate?: any;
    createBy?: string;
}

export interface PersonalHomeAddress {
    adressNo?: string;
    trok?: string;
    soi?: string;
    road?: string;
    postCode?: string;
    catm?: Catm;
    subDistrict?: SubDistrict;
    provinceId?: string;
}

export interface Province {
    id?: string;
    name?: string;
    zone?: Zone;
}

export interface Relation extends PanacheEntityBase {
    id?: number;
    name?: string;
    used?: string;
    code?: string;
}

export interface RelationshipRightsMember extends PanacheEntityBase {
    id?: RelationshipRightsMemberId;
    relation?: Relation;
    ownerData?: PersonalData;
    memberData?: PersonalData;
    fileUpload?: string;
    fileNameOriginal?: string;
}

export interface RelationshipRightsMemberId {
    ownerId?: string;
    memberId?: string;
}

export interface Status {
    status?: string;
    statusDesc?: string;
    shortDesc?: string;
    owner?: StatusOwner;
}

export interface SubDistrict {
    code?: string;
    name?: string;
    district?: District;
    catm?: string;
}

export interface TitleName {
    id?: number;
    tname?: string;
    code?: string;
    sex?: string;
    ty?: string;
}

export interface Zone {
    id?: string;
    name?: string;
}

export interface AttachmentGroupDto {
    group?: string;
    name?: string;
    required?: number;
}

export interface AttachmentRule {
    atrId?: number;
    type?: AttachmentType;
    used?: string;
    createDate?: any;
    lastUpdate?: any;
    registrantType?: AttachmentRuleRegistrantType;
    addressType?: AttachmentRuleAddressType;
    mainInsclCode?: string;
    subInsclCode?: string;
    attachmentsType?: AttachmentType;
}

export interface AttachmentTypeDto {
    id?: number;
    name?: string;
    group?: string;
    attachmentGroup?: AttachmentGroupDto;
}

export interface AttachmentsRulesSearchDto {
    pid?: string;
    registrantType?: RegistrantType;
    addressType?: AddressType;
    subInsclCode?: string;
}

export interface ContactInfoDto {
    mobile?: string;
    tel?: string;
    email?: string;
    lineId?: string;
}

export interface DistrictDto {
    code?: string;
    name?: string;
    province?: ProvinceDto;
}

export interface FileUploadDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    bucket?: Bucket;
    contentType?: string;
    fileSize?: number;
    originalName?: string;
    filename?: string;
    path?: string;
}

export interface MasterCupSearchHsubDto {
    hsub?: string;
    hmain?: string;
    hmainOp?: string;
    name?: string;
    tumbonName?: string;
    amphurName?: string;
    changwatName?: string;
}

export interface PersonSearchParams {
    pid?: string;
    birthDate?: string;
    relationId?: number;
}

export interface PersonSearchResultDto {
    birthDate?: string;
    firstName?: string;
    lastName?: string;
    personalId?: string;
    relation?: Relation;
}

export interface PersonalDataDto {
    birthDate?: string;
    fatherId?: string;
    motherId?: string;
    firstName?: string;
    lastName?: string;
}

export interface PersonalDataInfoDto {
    personalId?: string;
    titleNameId?: string;
    firstName?: string;
    lastName?: string;
    homeAddress?: PersonalHomeAddress;
    nation?: Nation;
}

export interface PhrBenefitDto {
    status?: string;
    description?: string;
    content?: JsonContent;
    JsonContent?: any;
}

export interface JsonContent {
    pid?: string;
    fname?: string;
    lname?: string;
    birthdate?: string;
    sex?: string;
    maininscl?: string;
    subinscl?: string;
    hmain?: string;
    hsub?: string;
    age_year?: number;
    age_month?: number;
    maininscl_desc?: string;
    subinscl_desc?: string;
    hmain_desc?: string;
    hsub_desc?: string;
    hmain_op?: string;
    hmain_op_desc?: string;
    service_list?: Service[];
}

export interface Service {
    class_id?: string;
    class_name?: string;
    service_id?: string;
    service_name?: string;
    package_target_group?: string;
    package_type?: string;
    service_type?: string;
    service_status?: string;
    start_date?: string;
    end_date?: string;
}

export interface PhrBenefitParams {
    pid?: string;
}

export interface ProvinceDto {
    id?: string;
    name?: string;
    zone?: ZoneDto;
}

export interface PublicRightCheckRequest {
    pid?: string;
    birthDate?: string;
    turnstileResponse?: string;
}

export interface RegisterRequest {
    personalDataInfo?: PersonalDataInfoDto;
    buildingType?: BuildingType;
    mastercup?: MastercupDto;
    registrantType?: RegistrantType;
    addressType?: AddressType;
    contactInfo?: ContactInfoDto;
    addressNo?: string;
    moo?: string;
    trok?: string;
    soi?: string;
    road?: string;
    postCode?: string;
    subDistrict?: SubDistrictDto;
    catmNear?: string;
    regType?: SrmRegType;
    fund?: FundDto;
    filesMap?: { [index: string]: SrmRegisterFileDto };
    accept?: boolean;
    registerMethod?: RegisterMethod;
    noIdCard?: boolean;
}

export interface RelationshipRightMemberRequest {
    memberPid?: string;
    relation?: Relation;
    fileUpload?: string;
    fileNameOriginal?: string;
}

export interface SearchCatmRequest {
    provinceName?: string;
    districtName?: string;
    subDistrictName?: string;
}

export interface SearchCatmResponse {
    province?: Province;
    district?: District;
    subDistrict?: SubDistrict;
    catm?: string;
}

export interface SrmGroupRegisterFileDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    bucket?: Bucket;
    contentType?: string;
    fileSize?: number;
    originalName?: string;
    filename?: string;
    fileType?: GroupRegisterFileType;
}

export interface SrmRegisterFileDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    bucket?: Bucket;
    contentType?: string;
    fileSize?: number;
    originalName?: string;
    filename?: string;
    fileType?: AttachmentTypeDto;
    fileTypeId?: number;
}

export interface SubDistrictDto {
    code?: string;
    name?: string;
    district?: DistrictDto;
    catm?: string;
}

export interface ZoneDto {
    id?: string;
    name?: string;
}

export interface AddressDto {
    postcode?: string;
    town?: string;
    quarter?: string;
    suburb?: string;
    city?: string;
    city_district?: string;
    county?: string;
    province?: string;
}

export interface FileResponseDto {
    filename?: string;
    originalName?: string;
    bucket?: Bucket;
    contentType?: string;
    fileSize?: number;
    createdTime?: any;
}

export interface FundDto {
    personalId?: string;
    fundType?: string;
    mainInscl?: string;
    mainInsclDesc?: string;
    ownerFirstName?: string;
    ownerLastName?: string;
    ownerPersonalId?: string;
    ownerTitleName?: string;
    startDate?: string;
    expireDate?: string;
    subInscl?: string;
    subInsclDesc?: string;
    countSelect?: number;
    hospMainOp?: string;
    hospMainOpName?: string;
    hospSss?: string;
    hospSssName?: string;
    hospSub?: string;
    hospSubName?: string;
    hospMain?: string;
    hospMainName?: string;
    paidModel?: string;
    cardId?: string;
    province?: string;
    provinceName?: string;
    status?: string;
    mastercupQuota?: string;
}

export interface MastercupDto {
    id?: string;
    used?: string;
    remark?: string;
    mastercupId?: string;
    provinceId?: string;
    provinceName?: string;
    hospMainCode?: string;
    hospMainName?: string;
    hospSubCode?: string;
    hospSubName?: string;
    hospMainOpCode?: string;
    hospMainOpName?: string;
    paidModel?: string;
    dateInCup?: string;
    dateOutCup?: string;
    quota?: string;
    catm?: string;
    catmNear?: string;
    selectable?: boolean;
    hospSubDisplayName?: string;
    hospMainOpDisplayName?: string;
    hospMainDisplayName?: string;
    latitude?: number;
    longitude?: number;
}

export interface MastercupSearchParams {
    catm?: string;
    moo?: string;
    page?: number;
    size?: number;
    sort?: string[];
}

export interface RegMainCitizenDto {
    transId?: number;
    personalId?: string;
    firstName?: string;
    lastName?: string;
    titleName?: string;
    startDate?: string;
    regProvinceId?: string;
    regProvinceName?: string;
    hospMainCode?: string;
    hospMainName?: string;
    hospSubCode?: string;
    hospSubName?: string;
    hospMainOpCode?: string;
    hospMainOpName?: string;
    createpid?: string;
    mainInsclId?: string;
    mainInsclName?: string;
    subInsclId?: string;
    subInsclName?: string;
    registrantType?: string;
    createdDateTime?: any;
    updatedDateTime?: any;
}

export interface RegMainCitizenParams {
    personalId?: string;
    createpid?: string;
    page?: number;
    size?: number;
    sort?: string[];
}

export interface RegisterResponse {
    createdId?: number;
    createDate?: string;
    lastUpdateId?: number;
    lastUpdateDate?: string;
    id?: number;
}

export interface ReverseAddressRequest {
    lat?: number;
    lon?: number;
    format?: string;
    zoom?: string;
    addressdetails?: string;
}

export interface ReverseAddressResponse {
    lat?: number;
    lon?: number;
    display_name?: string;
    address?: AddressDto;
}

export interface RightsDto {
    checkDate?: string;
    firstName?: string;
    lastName?: string;
    birthDate?: string;
    sexCode?: string;
    sexDesc?: string;
    personalId?: string;
    statusDola?: string;
    statusDolaDesc?: string;
    fatherId?: string;
    motherId?: string;
    fundList?: FundDto[];
    nationCode?: string;
}

export interface RightsHistoryDto {
    transDate?: string;
    groupId?: number;
    fundType?: string;
    mainInscl?: string;
    mainInsclDesc?: string;
    startDate?: string;
    expireDate?: string;
    subInscl?: string;
    subInsclDesc?: string;
    hospMainOp?: string;
    hospMainOpName?: string;
    hospSub?: string;
    hospSubName?: string;
    hospMain?: string;
    hospMainName?: string;
    cardId?: string;
    province?: string;
    provinceName?: string;
    statusDesc?: string;
}

export interface SearchAddressRequest {
    q?: string;
    addressdetails?: string;
    format?: string;
    limit?: string;
}

export interface SearchAddressResponse {
    lat?: number;
    lon?: number;
    name?: string;
}

export interface MastercupRequest {
    id?: string;
    used?: string;
    remark?: string;
    mastercupId?: string;
    provinceId?: string;
    hospMainCode?: string;
    hospSubCode?: string;
    hospMainOpCode?: string;
    catm?: string;
}

export interface PersonalFundInfo {
    personalId?: string;
    mainInsclId?: string;
    ownerPersonalId?: string;
}

export interface SrmRegisterRequest {
    createdPersonalId?: string;
    registrantType?: RegistrantType;
    personalData?: PersonalDataInfoDto;
    personalFund?: PersonalFundInfo;
    mastercup?: MastercupRequest;
    regType?: SrmRegType;
    contactInfo?: ContactInfoDto;
    addressNo?: string;
    moo?: string;
    trok?: string;
    soi?: string;
    road?: string;
    postCode?: string;
    subDistrict?: SubDistrictDto;
    addressType?: AddressType;
    buildingType?: BuildingType;
    filesMap?: { [index: string]: SrmRegisterFileDto };
    registerMethod?: RegisterMethod;
    noIdCard?: boolean;
}

export interface MyPage<T> {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    number?: number;
    content?: T[];
    numberOfElements?: number;
}

export interface UserData {
    fname?: string;
    lname?: string;
    pid?: string;
    fullName?: string;
}

export interface Pageable {
    paged?: boolean;
    unpaged?: boolean;
    pageSize?: number;
    pageNumber?: number;
    offset?: number;
    sort?: any;
}

export interface PanacheEntityBase {
}

export interface Streamable<T> extends Supplier<Stream<T>> {
    empty?: boolean;
}

export interface Supplier<T> {
}

export interface Stream<T> extends BaseStream<T, Stream<T>> {
}

export interface BaseStream<T, S> extends AutoCloseable {
    parallel?: boolean;
}

export interface AutoCloseable {
}

export type Bucket = "temp" | "srm" | "erm2";

export type AddressType = "MATCH" | "MISMATCH" | "PINNED";

export type AttachmentRuleAddressType = "CARDFACE" | "NOT_CARDFACE";

export type AttachmentRuleRegistrantType = "SELF" | "AGENT";

export type GroupRegisterFileType = "POWER_OF_AUTHORITY" | "FORWARDING_LETTER";

export type Mode = "CREATE" | "READ" | "UPDATE" | "DELETE";

export type RegisterMethod = "THAID" | "IDCARD" | "PERSONAL_ID";

export type RegistrantType = "SELF" | "GUARANTOR" | "FAMILY";

export type Sex = "NULL" | "MALE" | "FEMALE";

export type SrmRegType = "Y" | "H" | "R" | "W" | "S" | "G";

export type StatusOwner = "NHSO" | "BORA";

export type YesNo = "Y" | "N";


// Added by 'EnumTypeAliasExtension' extension
export type AllEnumClass = "Bucket" | "AddressType" | "AttachmentRuleAddressType" | "AttachmentRuleRegistrantType" | "GroupRegisterFileType" | "Mode" | "RegisterMethod" | "RegistrantType" | "Sex" | "SrmRegType" | "StatusOwner" | "YesNo"
// Enum description
//skip Bucket because it not have getValue() method
export const AddressType : {[p in AddressType] : string } = {
   MATCH:'ตรงตามทะเบียนบ้าน',
   MISMATCH:'ไม่ตรงตามทะเบียนบ้าน',
   PINNED:'เลือกที่อยู่โดยปักหมุด'
}
//skip AttachmentRuleAddressType because it not have getValue() method
//skip AttachmentRuleRegistrantType because it not have getValue() method
export const GroupRegisterFileType : {[p in GroupRegisterFileType] : string } = {
   POWER_OF_AUTHORITY:'หนังสือมอบอำนาจให้บันทึกข้อมูลลงทะเบียน',
   FORWARDING_LETTER:'หนังสือนำส่ง'
}
//skip Mode because it not have getValue() method
//skip RegisterMethod because it not have getValue() method
export const RegistrantType : {[p in RegistrantType] : string } = {
   SELF:'ตัวเอง',
   GUARANTOR:'โดยผู้มอบอำนาจ',
   FAMILY:'ครอบครัว'
}
export const Sex : {[p in Sex] : string } = {
   NULL:'null',
   MALE:'ชาย',
   FEMALE:'หญิง'
}
export const SrmRegType : {[p in SrmRegType] : string } = {
   Y:'ลงทะเบียนสิทธิว่างรายใหม่',
   H:'เปลี่ยนหน่วยบริการ',
   R:'เปลี่ยนและต่ออายุสิทธิย่อย',
   W:'ยอมรับลงทะเบียนตามมติบอร์ด',
   S:'แก้ไขลงทะเบียนตามมติบอร์ด',
   G:'ลงทะเบียนแบบกลุ่ม'
}
//skip StatusOwner because it not have getValue() method
export const YesNo : {[p in YesNo] : string } = {
   Y:'Yes',
   N:'No'
}


// Added by 'EnumTypeArrayExtension' extension
export const BucketArray : Bucket[]  = ['temp','srm','erm2']
export const AddressTypeArray : AddressType[]  = ['MATCH','MISMATCH','PINNED']
export const AttachmentRuleAddressTypeArray : AttachmentRuleAddressType[]  = ['CARDFACE','NOT_CARDFACE']
export const AttachmentRuleRegistrantTypeArray : AttachmentRuleRegistrantType[]  = ['SELF','AGENT']
export const GroupRegisterFileTypeArray : GroupRegisterFileType[]  = ['POWER_OF_AUTHORITY','FORWARDING_LETTER']
export const ModeArray : Mode[]  = ['CREATE','READ','UPDATE','DELETE']
export const RegisterMethodArray : RegisterMethod[]  = ['THAID','IDCARD','PERSONAL_ID']
export const RegistrantTypeArray : RegistrantType[]  = ['SELF','GUARANTOR','FAMILY']
export const SexArray : Sex[]  = ['NULL','MALE','FEMALE']
export const SrmRegTypeArray : SrmRegType[]  = ['Y','H','R','W','S','G']
export const StatusOwnerArray : StatusOwner[]  = ['NHSO','BORA']
export const YesNoArray : YesNo[]  = ['Y','N']
