import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonalDataResourceService {
  http = inject(HttpClient)

 public getPersonalDataInfo(pid:string){
    return this.http.get(`${environment.serverUrl}/api/secure/personal-info/${pid}`)
 }
}
