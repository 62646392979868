import {Component, input} from '@angular/core';
import {MastercupDto} from "../../../../generated-model/model";

@Component({
  selector: 'app-mastercup-status',
  standalone: true,
  imports: [],
  templateUrl: './mastercup-status.component.html',
  styleUrl: './mastercup-status.component.scss'
})
export class MastercupStatusComponent {
  mastercup = input.required<MastercupDto>();
}
