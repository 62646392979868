import {Component, effect, input, signal} from '@angular/core';
import {NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {RegistrationUtils} from '../../shere/commons/utils/registration-utils';
import {RegisterRequest, RightsDto} from '../../../generated-model/model';
import {AcceptBoardComponent} from './accept-board/accept-board.component';
import {EditBoardComponent} from './edit-board/edit-board.component';
import {RegisterNewComponent} from './register-new/register-new.component';
import {ChangeHospitalDetailComponent} from './change-hospital/change-hospital-detail/change-hospital-detail.component';
import {AcceptDealComponent} from './accept-deal/accept-deal.component';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-register-form',
  imports: [
    NgbNavModule,
    AcceptBoardComponent,
    EditBoardComponent,
    RegisterNewComponent,
    ChangeHospitalDetailComponent,
    AcceptDealComponent,
    FormsModule
  ],
  templateUrl: './register-form.component.html',
  standalone: true,
  styleUrl: './register-form.component.scss'
})
export class RegisterFormComponent {
  request = input.required<RegisterRequest>()
  data = input.required<RightsDto>();
  active = signal<number>(undefined);

  constructor() {
    effect(() => {
      this.handleChange();
    });
  }

  handleChange() {
    if (this.data()?.fundList?.length) {
      this.request().fund = this.data().fundList[0];
      if (this.request().personalDataInfo == undefined) {
        this.request().personalDataInfo = {}
      }
      this.request().personalDataInfo.personalId = this.data().personalId
      //this.request().mainInscl = this.request().personalFund.mainInscl;
      if (this.showRegisterNew()) {
        this.active.set(1);
      } else if (this.showRegisterBoard()) {
        this.active.set(2);
      } else if (this.showChangeHospital()) {
        this.active.set(4);
      }
      console.log('active : ', this.active())
    }
  }

  showRegisterPanel(): boolean {
    return RegistrationUtils.showRegisterPanel(this.data(), this.request().fund);
  }

  showRegisterNew(): boolean {
    return RegistrationUtils.showRegisterNew(this.data(), this.request().fund);
  }

  showRegisterBoard(): boolean {
    return RegistrationUtils.showRegisterBoard(this.request().fund);
  }

  showChangeHospital(): boolean {
    return RegistrationUtils.showChangeHospital(this.request().fund);
  }

  onRegTypeChange() {
  }
}
