import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatDateInputService {
  public formatBirthToYYYYMMDD(value: string): string {
    const [day, month, year] = value.split('/');
    return `${year}${month}${day}`;
  }
}
