import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {Directive, forwardRef, input} from "@angular/core";
import {FileUploadDto} from "../../../generated-model/model";


@Directive({
  selector: 'app-file-upload [fileRequired] [ngModel]',
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileRequiredValidator),
      multi: true
    }
  ]
})
export class FileRequiredValidator implements Validator {
  fileRequired = input<boolean>(false);

  validate(control: AbstractControl<FileUploadDto, any>): ValidationErrors {
    if (this.fileRequired()) {
      if (!control.value?.originalName) {
        return {fileRequired: true};
      }
    }
    return undefined;
  }

  registerOnValidatorChange?(fn: () => void): void;

}
