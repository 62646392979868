import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {Directive, forwardRef, input} from "@angular/core";

/**
 * validate file size in MB
 */
@Directive({
  selector: 'app-file-upload [fileSizeLimit] [ngModel]',
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileSizeLimitValidator),
      multi: true
    }
  ]
})
export class FileSizeLimitValidator implements Validator {
  fileSizeLimit = input<number>(5);
  fileSize = input.required<number>();

  validate(control: AbstractControl<any, any>): ValidationErrors {
    // console.log('control ', control)
    if (control.value?.fileSize > 0) {
      let fileSizeMB = control.value.fileSize / (1024 ** 2);
      if (fileSizeMB > this.fileSizeLimit()) {
        return {fileSizeLimit: true};
      }
    }
    return undefined;
  }

  registerOnValidatorChange?(fn: () => void): void;

}
