import {Component, inject, model, signal} from '@angular/core';
import {NgOptimizedImage} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Router} from '@angular/router';
import {UcDetailComponent} from '../../secure/right/right-detail/uc/uc-detail.component';
import {SssDetailComponent} from '../../secure/right/right-detail/sss/sss-detail.component';
import {PvtDetailComponent} from '../../secure/right/right-detail/pvt/pvt-detail.component';
import {WvoDetailComponent} from '../../secure/right/right-detail/wvo/wvo-detail.component';
import {VetDetailComponent} from '../../secure/right/right-detail/vet/vet-detail.component';
import {DisDetailComponent} from '../../secure/right/right-detail/dis/dis-detail.component';
import {NrdNruFrgDetailComponent} from '../../secure/right/right-detail/nrd-nru-frg/nrd-nru-frg-detail.component';
import {NrhDetailComponent} from '../../secure/right/right-detail/nrh/nrh-detail.component';
import {St1St2StpDetailComponent} from '../../secure/right/right-detail/st1-st2-stp/st1-st2-stp-detail.component';
import {
  OfcLgoSsiBfcDetailComponent
} from '../../secure/right/right-detail/ofc-lgo-ssi-bfc/ofc-lgo-ssi-bfc-detail.component';
import {RightsDto} from '../../../generated-model/model';
import {ThaiDateTimePipe} from '../../shere/commons/thai-datetime.pipe';
import {BirthdateWithoutDate} from '../../shere/birthdate-without-date';

@Component({
  selector: 'app-public-right-detail',
  imports: [
    FormsModule,
    NgOptimizedImage,
    UcDetailComponent,
    SssDetailComponent,
    PvtDetailComponent,
    WvoDetailComponent,
    VetDetailComponent,
    DisDetailComponent,
    NrdNruFrgDetailComponent,
    NrhDetailComponent,
    St1St2StpDetailComponent,
    OfcLgoSsiBfcDetailComponent,
    ThaiDateTimePipe,
    BirthdateWithoutDate
  ],
  templateUrl: './public-right-detail.component.html',
  standalone: true,
  styleUrl: './public-right-detail.component.scss'
})
export class PublicRightDetailComponent {
  personalId = model<string>(undefined)
  data = signal(undefined as RightsDto)
  birthdate = model<string>(undefined)
  router = inject(Router)

  constructor() {
    this.data.set(this.router.getCurrentNavigation()?.extras.state)
  }
}
