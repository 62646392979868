import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BuildingType} from '../../generated-model/model';

@Injectable({
  providedIn: 'root'
})
export class BuildingTypeResourceService {
  http = inject(HttpClient)

  public getAllBuildingType(){
    return this.http.get<BuildingType[]>(`${environment.serverUrl}/api/secure/all-building-type`)
  }
}
