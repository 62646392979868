import {Component, inject, input, OnInit, signal, ViewChild} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from "@angular/forms";
import {NgIf} from "@angular/common";
import {SharedCommonModule} from '../../../shere/commons/shared-common.module';
import {CustomErrorComponent} from '../../../shere/commons/custom-error.component';
import {RegisterRequest} from '../../../../generated-model/model';
import {filter} from 'rxjs';
import {scrollToInvalidElement} from '../../../app.constants';
import {ConfirmDialogService} from '../../../shere/commons/dialog/confirm-dialog/confirm-dialog.service';
import {ToastService} from '../../../shere/commons/toast/toast-service';
import {RegisterResource} from '../../../service/register-resource.service';
import {RelationshipRightsMemberService} from '../../../service/relationship-rights-member.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-accept-board',
  standalone: true,
  imports: [
    FormsModule,
    SharedCommonModule,
    CustomErrorComponent,
    NgIf
  ],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm
  }],
  templateUrl: './accept-board.component.html',
  styleUrl: './accept-board.component.scss'
})
export class AcceptBoardComponent implements OnInit {
  request = input.required<RegisterRequest>();
  confirmDialogService = inject(ConfirmDialogService)
  toastService = inject(ToastService)
  registerResource = inject(RegisterResource)
  relationshipRightsMemberResource = inject(RelationshipRightsMemberService)
  router = inject(Router)

  @ViewChild('form') public ngForm: NgForm;

  ngOnInit(): void {
    this.request().contactInfo ??= {};
    this.request().regType = 'W';
  }

  confirmReg() {
    console.log(this.request())
    this.ngForm.control.markAllAsTouched()
    if (this.ngForm.valid) {
      this.confirmDialogService.open({content: 'ยืนยันการลงทะเบียน'})
        .pipe(filter(e => !!e))
        .subscribe({
          next: e => {
            //this.progressionDialogService.open({content: "กำลังบันทึกข้อมูล..."});
            this.registerResource.acceptBoard(this.request()).subscribe({
                next: value => {
                  this.toastService.showSuccess("ลงทะเบียนสำเสร็จ")
                  if (this.request().registrantType == 'FAMILY') {
                    this.relationshipRightsMemberResource.getMember(this.request().personalDataInfo.personalId).subscribe({
                      next: member => {
                        this.router.navigate(['secure', 'select-family-member-detail'], {state: {...member}});
                      }
                    })
                  } else {
                    this.router.navigate(['secure', 'self-right-check'])
                  }
                },
                error: err => {
                  console.log(err)
                }
              }
            )

          }
        });
    } else {
      this.toastService.showWarning("กรุณากรอกข้อมูลให้ครบถ้วน")
      try {
        scrollToInvalidElement('request');
      } catch (e) {
        console.log(e)
      }
    }
  }
}
