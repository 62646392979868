import {Component, ElementRef, inject, OnInit, signal, ViewChild} from '@angular/core';
import {
  NgbActiveModal,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import {RelationResourceService} from '../../../../../service/relation-resource.service';
import {
  FileUploadDto,
  Mode,
  PersonSearchParams,
  PersonSearchResultDto,
  Relation,
  RelationshipRightMemberRequest,
  RelationshipRightsMember
} from '../../../../../../generated-model/model';
import {FormsModule, NgForm} from '@angular/forms';
import {SharedCommonModule} from '../../../../../shere/commons/shared-common.module';
import {CustomErrorComponent} from '../../../../../shere/commons/custom-error.component';
import {NgIf} from '@angular/common';
import {FormatDateInputService} from '../../../../../shere/commons/service/format-date-input.service';
import {RightCheckResource} from '../../../../../service/right-check-resource.service';
import {CustomNgbDateParser} from '../../../../../shere/gt-ng-datepicker/custom-ngb-date-parser';
import {ThaiDateTimePipe} from '../../../../../shere/commons/thai-datetime.pipe';
import {RelationshipRightsMemberService} from '../../../../../service/relationship-rights-member.service';
import {ThaiDateFullPipe} from '../../../../../shere/commons/thai-date-full.pipe';
import {ConfirmDialogService} from '../../../../../shere/commons/dialog/confirm-dialog/confirm-dialog.service';
import {filter} from 'rxjs';
import {ToastService} from '../../../../../shere/commons/toast/toast-service';
import {FileUploadModule} from 'ng2-file-upload';
import {FileUploadComponent} from '../../../../../shere/commons/file-upload/file-upload.component';
import {FileRequiredValidator} from "../../../../../shere/validator/file-required-validator.directive";
import {FileSizeLimitValidator} from "../../../../../shere/validator/file-size-limit-validator.directive";

@Component({
  selector: 'app-add-family-modal',
  imports: [
    FormsModule,
    SharedCommonModule,
    NgbDatepickerModule,
    CustomErrorComponent,
    NgIf,
    ThaiDateTimePipe,
    ThaiDateFullPipe,
    FileUploadModule,
    FileUploadComponent,
    FileRequiredValidator,
    FileSizeLimitValidator
  ],
  templateUrl: './add-family-modal.component.html',
  standalone: true,
  styleUrl: './add-family-modal.component.scss',
  providers: [{provide: NgbDateParserFormatter, useClass: CustomNgbDateParser}]
})
export class AddFamilyModalComponent implements OnInit {
  activeModal = inject(NgbActiveModal)
  relationResourceService = inject(RelationResourceService)
  formatDateInput = inject(FormatDateInputService)
  rightCheckResourceService = inject(RightCheckResource)
  relationRightMemberService = inject(RelationshipRightsMemberService)
  confirmDialogService = inject(ConfirmDialogService)
  toastService = inject(ToastService)

  relations = signal([] as Relation[])
  searchParams = signal({} as PersonSearchParams)
  result = signal({} as PersonSearchResultDto)
  member = signal({} as RelationshipRightsMember)
  request = signal({} as RelationshipRightMemberRequest)
  file: FileUploadDto = {}
  now = new Date()
  maxDate: NgbDateStruct
  mode: Mode

  @ViewChild('birthDateElement') birthDate: ElementRef<HTMLInputElement>
  @ViewChild('form') public ngForm: NgForm;
  resultNotfound: boolean = false;

  ngOnInit() {
    this.maxDate = {year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate()}
    this.relationResourceService.getAllRelation().subscribe({
      next: value => {
        this.relations.set(value)
      }, error: err => {
        if (err?.status == 400) {
          this.relations.set([])
          console.log(err)
        }
      }
    })
  }

  constructor() {
    console.log(this.mode)
  }

  close() {
    this.activeModal.close('cross click')
  }

  search() {
    this.ngForm.control.markAllAsTouched()
    if (this.birthDate.nativeElement.value) {
      const searchParams = {...this.searchParams()}
      searchParams.birthDate = this.formatDateInput.formatBirthToYYYYMMDD(this.birthDate.nativeElement.value)
      this.rightCheckResourceService.searchPersonWithBirthDate(searchParams).subscribe({
        next: value => {
          if (value.personalId) {
            this.result.set(value)
          } else {
            this.resultNotfound = true
          }
        }
      })
    }
  }

  dateSelect($event: NgbDate) {
    console.log($event)
  }

  saveMember() {
    this.ngForm.control.markAllAsTouched()
    this.request().memberPid = this.result().personalId
    this.request().relation = this.result().relation
    this.request().fileUpload = this.file.filename
    this.request().fileNameOriginal = this.file.originalName
    if (this.ngForm.valid) {
      this.confirmDialogService.open({content: 'ยืนยันการบันทึก?'})
        .pipe(filter(e => !!e))
        .subscribe({
          next: e => {
            console.log('origin', this.request().fileNameOriginal)
            this.relationRightMemberService.saveMember(this.request()).subscribe({
              next: value => {
                this.toastService.showSuccess("บันทึกสำเร็จ")
                this.activeModal.close('save success')
              }
            })
          }
        })
    }
  }

  updateMember() {
    this.request().memberPid = this.member().memberData.personalId
    this.request().relation = this.member().relation
    this.confirmDialogService.open({content: 'ยืนยันการแก้ไขข้อมูล?'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
          this.relationRightMemberService.saveMember(this.request()).subscribe({
            next: value => {
              this.toastService.showSuccess("แก้ไขข้อมูลสำเร็จ")
              this.activeModal.close('update success')
            }
          })
        }
      })
  }
}
