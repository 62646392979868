<div class="justify-content-center align-middle text-center">
  <!--  <i class="bi bi-inboxes fs-1 text-muted"></i>-->
  <!--  <i class="bi bi-collection fs-1 text-muted"></i>-->
  <!--  <i class="bi bi-box-seam fs-1 text-muted"></i>-->
  <!--  <i class="bi bi-inbox fs-1 text-muted"></i>-->
  <img src="/assets/images/open-box_362342.png" alt="" height="25">
  <p class="text-muted">
    -- ไม่พบข้อมูล --
  </p>
</div>
