import {inject, Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddFamilyModalComponent} from './add-family-modal.component';
import {from} from 'rxjs';
import {RelationshipRightsMember} from '../../../../../../generated-model/model';

@Injectable({
  providedIn: 'root'
})
export class AddFamilyModalService {
  private modalService = inject(NgbModal)

  constructor() {
  }

  open(){
    const modalRef = this.modalService.open(AddFamilyModalComponent, {
      centered: true,
      size: "xl",
      keyboard: true
    });
    const component: AddFamilyModalComponent = modalRef.componentInstance;
    component.mode = 'CREATE'
    return from(modalRef.result)
  }

  edit(member:RelationshipRightsMember){
    const modalRef = this.modalService.open(AddFamilyModalComponent, {
      centered: true,
      size: "xl",
      keyboard: true
    });
    const component: AddFamilyModalComponent = modalRef.componentInstance;
    component.member.set(member)
    component.mode = 'UPDATE'
    return from(modalRef.result)
  }
}
