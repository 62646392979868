import {Component, inject, model, ViewChild} from '@angular/core';
import {RegisterRequest} from '../../../../generated-model/model';
import {NgForm} from '@angular/forms';
import {filter} from 'rxjs';
import {ConfirmDialogService} from '../../../shere/commons/dialog/confirm-dialog/confirm-dialog.service';
import {ProgressionDialogService} from '../../../shere/commons/dialog/progression-dialog/progression-dialog.service';

@Component({
  selector: 'app-accept-deal',
  imports: [],
  templateUrl: './accept-deal.component.html',
  standalone: true,
  styleUrl: './accept-deal.component.scss'
})
export class AcceptDealComponent {
  request = model.required<RegisterRequest>();
  formValid: boolean
  @ViewChild('form') public ngForm: NgForm;
  confirmDialogService = inject(ConfirmDialogService)
  progressionDialogService =inject(ProgressionDialogService)

  confirmReg() {
    this.confirmDialogService.open({content: 'ยืนยันการลงทะเบียน'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
          //this.progressionDialogService.open({content: "กำลังบันทึกข้อมูล..."});
          //todo save
        }
      });
  }
}
