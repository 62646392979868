import {Component, inject, input, signal, ViewChild} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from "@angular/forms";
import {SharedCommonModule} from '../../../shere/commons/shared-common.module';
import {CustomErrorComponent} from '../../../shere/commons/custom-error.component';
import {KeyValuePipe, NgClass, NgIf} from '@angular/common';
import {
  AttachmentGroup,
  AttachmentGroupDto,
  AttachmentsRulesSearchDto,
  BuildingType,
  RegisterRequest,
  SrmRegisterFileDto
} from '../../../../generated-model/model';
import {SelectMastercupCatmService} from '../change-hospital/modal/select-mastercup-catm/select-mastercup-catm.service';
import {SelectMastercupService} from '../change-hospital/modal/select-mastercup/select-mastercup.service';
import {
  SelectBuildingTypeComponent
} from '../../../shere/component/select-building-type/select-building-type.component';
import {MastercupStatusComponent} from '../../../shere/component/mastercup-status/mastercup-status.component';
import {BuildingTypeResourceService} from '../../../service/building-type-resource.service';
import {PersonalDataResourceService} from '../../../service/personal-data-resource.service';
import {AttachmentResource} from '../../../service/attachment-resource.service';
import {MAP_ORDER_BY_KEY_STRING_ASC, scrollToInvalidElement} from '../../../app.constants';
import {FileGroupFilterPipe} from '../../../shere/commons/file-group-filter.pipe';
import {FileGroupValidatorDirective} from '../../../shere/validator/file-group-validator.directive';
import {FileRequiredValidator} from '../../../shere/validator/file-required-validator.directive';
import {FileSizeLimitValidator} from '../../../shere/validator/file-size-limit-validator.directive';
import {FileUploadComponent} from '../../../shere/commons/file-upload/file-upload.component';
import {filter} from 'rxjs';
import {ConfirmDialogService} from '../../../shere/commons/dialog/confirm-dialog/confirm-dialog.service';
import {ToastService} from '../../../shere/commons/toast/toast-service';
import {RegisterResource} from '../../../service/register-resource.service';
import {RelationshipRightsMemberService} from '../../../service/relationship-rights-member.service';
import {Router} from '@angular/router';
import {PinnedAddressService} from '../modal/pinned-address/pinned-address.service';

@Component({
  selector: 'app-edit-board',
  standalone: true,
  imports: [
    FormsModule,
    SharedCommonModule,
    CustomErrorComponent,
    NgIf,
    KeyValuePipe,
    NgClass,
    SelectBuildingTypeComponent,
    MastercupStatusComponent,
    FileGroupFilterPipe,
    FileGroupValidatorDirective,
    FileRequiredValidator,
    FileSizeLimitValidator,
    FileUploadComponent
  ],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm
  }],
  templateUrl: './edit-board.component.html',
  styleUrl: './edit-board.component.scss'
})
export class EditBoardComponent {
  protected readonly MAP_ORDER_BY_KEY_STRING_ASC = MAP_ORDER_BY_KEY_STRING_ASC;

  request = input.required<RegisterRequest>();
  selectMastercupService = inject(SelectMastercupService);
  selectCatmService = inject(SelectMastercupCatmService);
  buildingTypeResourceService = inject(BuildingTypeResourceService)
  personalDataResourceService = inject(PersonalDataResourceService)
  attachmentResource = inject(AttachmentResource)
  confirmDialogService = inject(ConfirmDialogService)
  toastService = inject(ToastService)
  registerResource = inject(RegisterResource)
  relationshipRightsMemberResource = inject(RelationshipRightsMemberService)
  router = inject(Router)
  pinnedAddressService = inject(PinnedAddressService)


  buildingTypes = signal([] as BuildingType[])
  attachmentGroupList = signal<AttachmentGroupDto[]>([])

  @ViewChild('form') public ngForm: NgForm;


  ngOnInit() {
    this.request().contactInfo ??= {}
    this.request().addressType = 'MATCH'

    this.buildingTypeResourceService.getAllBuildingType().subscribe({
      next: value => {
        this.buildingTypes.set(value)
      }
    })
    this.personalDataResourceService.getPersonalDataInfo(this.request().personalDataInfo.personalId).subscribe({
      next: value => {
        this.request().personalDataInfo = value
        this.request().subDistrict = this.request().personalDataInfo.homeAddress.subDistrict
        this.request().addressNo = this.request().personalDataInfo.homeAddress.adressNo
      }
    })
  }

  selectMastercup() {
    let subDistrict = this.request().subDistrict
    this.selectMastercupService.open(subDistrict).subscribe(mastercup => {
      this.request().mastercup = {...mastercup}
    })
  }

  onAddressTypeChange() {
    if (this.request().addressType == "MISMATCH") {
      this.selectNewMastercupCatm()
      this.getAttachmentRules()
    } else if (this.request().addressType == "PINNED") {
      this.pinnedAddress()
      // this.getAttachmentRules()
    } else {
      this.request().subDistrict = this.request().personalDataInfo?.homeAddress?.subDistrict;
      this.request().moo = undefined;
      this.request().mastercup = undefined;
      this.request().addressNo = this.request().personalDataInfo.homeAddress.adressNo
    }
  }

  selectNewMastercupCatm() {
    this.selectCatmService.open()
      .subscribe(newSubDistrict => {
        this.request().subDistrict = newSubDistrict?.subDistrict;
        this.request().moo = newSubDistrict?.moo;
        this.request().mastercup = undefined;
      });
  }

  confirmReg() {
    console.log(this.request())
    this.ngForm.control.markAllAsTouched()
    if (this.ngForm.valid) {
      this.confirmDialogService.open({content: 'ยืนยันการลงทะเบียน'})
        .pipe(filter(e => !!e))
        .subscribe({
          next: e => {
            this.registerResource.editBoard(this.request()).subscribe({
              next: value => {
                //this.progressionDialogService.open({content: "กำลังบันทึกข้อมูล..."});
                this.toastService.showSuccess("ลงทะเบียนเรียบร้อยแล้ว")
                if (this.request().registrantType == 'FAMILY') {
                  this.relationshipRightsMemberResource.getMember(this.request().personalDataInfo.personalId).subscribe({
                    next: member => {
                      this.router.navigate(['secure', 'select-family-member-detail'], {state: {...member}});
                    }
                  })
                } else {
                  this.router.navigate(['secure', 'self-right-check'])
                }
              }, error: err => {
                console.log(err)
              }
            })
          }
        });
    } else {
      this.toastService.showWarning("กรุณากรอกข้อมูลให้ครบถ้วน")
      try {
        scrollToInvalidElement('request');
      } catch (e) {
        console.log(e)
      }
    }
  }

  getAttachmentRules() {
    console.log('registrantType', this.request().registrantType)
    let request: AttachmentsRulesSearchDto = {
      registrantType: this.request().registrantType,
      addressType: this.request().addressType || 'MATCH',
      subInsclCode: this.request().fund.subInscl,
      pid: this.request().personalDataInfo.personalId
    }

    this.attachmentResource.searchRules(request)
      .subscribe(rules => {
        this.request().filesMap = {};
        let groupMap: { [index: string]: AttachmentGroup } = {};
        rules = rules.filter(rule => rule.type.group == '1')
        for (let rule of rules) {
          this.request().filesMap[rule.type.id] = {fileType: rule.type} as SrmRegisterFileDto;
          if (rule.type.group) {
            groupMap[rule.type.group] = rule.type.attachmentGroup;
          }
        }
        console.log('groupMap = ', Object.values(groupMap))
        this.attachmentGroupList.set(Object.values(groupMap));
      });
  }

  pinnedAddress() {
    this.pinnedAddressService.open().subscribe({
      next: value => {
        this.request().subDistrict = value
      }
    })
  }
}
