<ng-select [id]="id()"
           [name]="name()"
           [items]="districtList$ | async"
           placeholder="กรุณาเลือก"
           class="novalidate"
           [disabled]="disabled()"
           [clearable]="true"
           [compareWith]="compareWithCode"
           [editableSearchTerm]="true"
           [loading]="typeaheadLoading"
           (ngModelChange)="valueChange($event)"
           [(ngModel)]="value">
  <ng-template ng-label-tmp let-item="item">
    <ng-container *ngIf="item.code">
      {{ item.name }}
    </ng-container>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <ng-container *ngIf="item.code">
      {{ item.name }}
    </ng-container>
  </ng-template>
</ng-select>
