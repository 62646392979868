import {Component, inject, input, OnInit, signal} from '@angular/core';
import {RightCheckComponent} from '../../right/right-check/right-check.component';
import {
  SelfRightCheckDetailComponent
} from '../../right/self-right-check/self-right-check-detail/self-right-check-detail.component';
import {Router, RouterOutlet} from '@angular/router';
import {
  FamilyRightCheckDetailComponent
} from '../../right/family-right-check/family-right-check-detail/family-right-check-detail.component';
import {RelationshipRightsMemberService} from '../../../service/relationship-rights-member.service';
import {RightCheckResource} from '../../../service/right-check-resource.service';
import {RegisterRequest, RegistrantType, RelationshipRightsMember, RightsDto} from '../../../../generated-model/model';
import {RegisterFormComponent} from '../register-form.component';


@Component({
  imports: [
    RightCheckComponent,
    FamilyRightCheckDetailComponent,
    RouterOutlet,
    SelfRightCheckDetailComponent,
    RegisterFormComponent
  ],
  selector: 'app-change-hospital',
  standalone: true,
  styleUrl: './change-hospital.component.scss',
  templateUrl: './change-hospital.component.html'
})
export class ChangeHospitalComponent implements OnInit {
  router = inject(Router)
  rightCheckResourceService = inject(RightCheckResource)
  relationRightMemberService = inject(RelationshipRightsMemberService)

  registrantType: RegistrantType
  pid: string

  request = input.required<RegisterRequest>()
  data = signal(undefined as RightsDto)
  member = signal(undefined as RelationshipRightsMember)

  ngOnInit(): void {
    this.registrantType = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation().extras.state['registrantType'] : this.router.lastSuccessfulNavigation.extras.state['registrantType'];
    this.pid = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation().extras.state['pid'] : this.router.lastSuccessfulNavigation.extras.state['pid'];
    this.rightCheckResourceService.searchRight(this.pid).subscribe({
      next: value => {
        this.data.set(value)
      }, error: err => {
        if (err?.status == 400) {
          this.data.set(undefined);
          console.log(err)
        }
      }
    })
    if (this.registrantType == 'FAMILY') {
      this.relationRightMemberService.getMember(this.pid).subscribe({
        next: member => {
          this.member.set(member)
        }, error: err => {
          if (err?.status == 400) {
            this.member.set(undefined)
            console.log(err)
          }
        }
      })
    }
  }
}
