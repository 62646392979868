import {FundDto, RightsDto} from '../../../../generated-model/model';

export class RegistrationUtils {
  public static showRegisterPanel(selectPersonalData: RightsDto, selectPersonalFund: FundDto): boolean {
    if (!selectPersonalFund) {
      return false;
    }
    return (selectPersonalFund.mainInscl == undefined || selectPersonalFund.mainInscl == 'UCS' || selectPersonalFund.mainInscl == 'WEL' || selectPersonalFund.mainInscl == 'DIS')
      && (
        this.showRegisterNew(selectPersonalData, selectPersonalFund)
        || this.showRegisterBoard(selectPersonalFund)
        || this.showChangeHospital(selectPersonalFund)
      );
  }

  public static showRegisterNew(selectPersonalData: RightsDto, selectPersonalFund: FundDto): boolean {
    return ['005', '006', '007', '008'].includes(selectPersonalFund?.status) && selectPersonalData?.nationCode === '099';
  }

  public static showRegisterBoard(selectPersonalFund:FundDto): boolean {
    return selectPersonalFund?.status === '009';
  }

  public static showChangeHospital(selectPersonalFund: FundDto): boolean {
    return ((selectPersonalFund?.status === '004' || selectPersonalFund?.status === '010')
        && (selectPersonalFund?.mainInscl === 'UCS' || selectPersonalFund?.mainInscl === 'WEL'))
      || (selectPersonalFund?.mastercupQuota === '2')
      || (selectPersonalFund?.mainInscl === 'DIS' && !selectPersonalFund?.status)
  }
}
