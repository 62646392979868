<div class="container-fluid">
  <form #form=ngForm id="request">
    <div class="row mb-3">
      <label class="col-auto text-muted fw-semibold fs-6">ข้อมูลติดต่อ</label>
    </div>
    <div class="row mb-3">
      <label for="mobile" class="col-xl-2 col-form-label ui-require">เบอร์โทรศัพท์มือถือ</label>
      <div class="col-xl-4">
        <input type="text"
               required
               [(ngModel)]="request().contactInfo.mobile"
               class="form-control"
               #mobile="ngModel"
               pattern="^\d{10}$"
               id="mobile"
               name="mobile">
        @if (mobile.invalid && (mobile.dirty || mobile.touched)) {
          <custom-error *ngIf="mobile.hasError('required')" error="*จำเป็น"></custom-error>
          <custom-error *ngIf="mobile.hasError('pattern')" error="ระบุเป็นตัวเลข ความยาวเท่ากับ 10"></custom-error>
        }
      </div>
    </div>
    <div class="row mb-3">
      <label for="tel" class="col-xl-2 col-form-label">เบอร์โทรศัพท์</label>
      <div class="col-xl-4">
        <input type="text"
               [(ngModel)]="request().contactInfo.tel"
               class="form-control"
               maxlength="120"
               id="tel"
               name="tel">
        <div class="form-text">กรณีที่มีมากกว่า 1 เบอร์ ให้ใส่ , คั่น</div>
      </div>
    </div>
    <div class="row mb-3">
      <label for="email" class="col-xl-2 col-form-label">อีเมล</label>
      <div class="col-xl-4">
        <input type="text"
               [(ngModel)]="request().contactInfo.email"
               class="form-control"
               email
               id="email"
               #email="ngModel"
               name="email">
        @if (email.invalid && (email.dirty || email.touched)) {
          <custom-error *ngIf="email.hasError('email')" error="email ไม่ถูกต้อง"></custom-error>
        }
      </div>
    </div>
    <div class="row mb-3">
      <label for="line-id" class="col-xl-2 col-form-label">Line ID</label>
      <div class="col-xl-4">
        <input type="text"
               [(ngModel)]="request().contactInfo.lineId"
               class="form-control"
               id="line-id"
               name="line-id">
      </div>
    </div>
    <div class="card shadow-card mb-4">
      <div class="card-body">
        <div class="justify-content-xl-center justify-content-sm-start d-flex fw-bold mt-3">
          <input type="checkbox" class="form-check-input me-2" id="accept" name="accept" [(ngModel)]="request().accept"
                 #accept="ngModel" required>
          <label for="accept" class="form-check-label">รับข้อตกลง และยืนยันการลงทะเบียน</label>
        </div>
        <div class="justify-content-xl-center justify-content-sm-start d-flex">
          @if (accept.invalid && (accept.dirty || accept.touched)) {
            <custom-error *ngIf="accept.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
      </div>
    </div>
    <div class="text-center mb-4">
      <div class="col-12">
        <button class="btn btn-success" type="button" (click)="confirmReg()">
          <i class="bi bi-floppy-fill me-1"></i>
          ยืนยันการลงทะเบียน
        </button>
      </div>
    </div>
  </form>
</div>
