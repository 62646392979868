<form class="form-validate" #form="ngForm" (submit)="search()">
  <div class="modal-header">
    <h6 class="modal-title text-muted"><i class="bi bi-pin-map-fill"></i> เลือกที่อยู่โดยการปักหมุด</h6>
    <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.close('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="input-group mb-3 col-xl-8">
      <input type="text" class="form-control" placeholder="พิมพ์เพื่อค้นหา" aria-label="พิมพ์เพื่อค้นหา"
             aria-describedby="basic-addon2" id='keyword' name="keyword" [(ngModel)]="searchRequest().q">
      <div class="input-group-append">
        <button class="btn btn-gray" type="button" style="border-radius: .001rem .4rem .4rem .001rem" (click)="search()"><i class="ิbi bi-search"></i> ค้นหา</button>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div style="height: 65vh" leaflet
             [leafletOptions]="options"
             (leafletClick)="onClickMap($event)"
             [leafletLayers]="layers"
             (leafletMapReady)="onMapReady($event)"
        ></div>
      </div>
    </div>
  </div>
</form>
