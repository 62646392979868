<div class="row mb-3">
  <label for="subInsclDesc" class="col-xl-6 col-form-label text-xl-end">สิทธิที่ใช้เบิก</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="subInsclDesc" formDisabled
           [ngModel]="fund.subInsclDesc|| '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="startDate" class="col-xl-6 col-form-label text-xl-end">วันที่เริ่มสิทธิ</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="startDate"
           formDisabled [ngModel]="(fund?.startDate | thaidatetime:{month:'long',showTime:false}) || '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="expireDateTime" class="col-xl-6 col-form-label text-xl-end">วันที่หมดสิทธิ</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="expireDateTime"
           formDisabled [ngModel]="(fund?.expireDate | thaidatetime:{month:'long',showTime:false}) || '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="sssProvince" class="col-xl-6 col-form-label text-xl-end">จังหวัดที่สำนักงานประกันสังคมรับผิดชอบ</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="sssProvince"
           formDisabled [ngModel]="fund?.provinceName || '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="hospSssName" class="col-xl-6 col-form-label text-xl-end">รพ. รักษา(ประกันสังคม)</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="hospSssName"
           formDisabled [ngModel]="fund?.hospSssName || '-'">
  </div>
</div>
