<div class="text-start my-3">
  <h5 class="text-secondary fw-bold">ประวัติการลงทะเบียน UC</h5>
</div>
<div class="card shadow-card mb-4">
  <div class="card-header">
    <div><i class="bi bi-search"></i> ค้นหา</div>
  </div>
  <div class="card-body">
    <form #searchForm="ngForm" (ngSubmit)="search()">
      <div class="container my-3">
        <div class="row mt-xl-3 mt-sm-0 d-xl-flex justify-content-center">
          <div class="col-xl-6 col-sm-12 d-xl-flex">
            <label for="pid"
                   class="col-form-label col-xl-3 col-sm-12">เลขประจำตัวประชาชน</label>
            <div class="col-xl-8 col-sm-12">
              <input type="text" id="pid" class="form-control" name="pid" [(ngModel)]="personalId">
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="text-center">
            <button class="btn btn-primary fw-bold me-2" type="submit">
              <i class="bi bi-search fw-bold me-1"></i>
              ค้นหา
            </button>
            <button class="btn btn-secondary fw-bold" type="reset">
              <i class="bi bi-eraser-fill me-1"></i>
              เคลียร์ข้อมูล
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="card  shadow-card mb-4">
  <div class="card-header">
    <div>
      ผลการค้นหาตามเงื่อนไขรายการที่ {{pageData.totalElements}} จากทั้งหมด รายการ
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-bordered caption-top text-center">
        <thead>
        <tr>
          <th rowspan="3">ลำดับ</th>
          <th rowspan="3" nowrap>วันที่ลงทะเบียน</th>
          <th rowspan="3" nowrap>วันที่เริ่มใช้สิทธิ</th>
          <th rowspan="3" nowrap>ประเภทการยื่นคำร้อง</th>
          <th rowspan="3" nowrap>เลขที่ประจำตัวประชาชน<br>(ผู้ขอลงทะเบียน)</th>
          <th rowspan="3" nowrap>ชื่อ-นามสกุล<br>(ผู้ขอลงทะเบียน)</th>
          <th colspan="8">เครือข่ายหน่วยบริการที่ลงทะเบียนใหม่</th>
          <th rowspan="3" nowrap>รหัสสิทธิหลัก</th>
          <th rowspan="3" nowrap>สิทธิย่อย</th>
        </tr>
        <tr>
          <th colspan="2" nowrap>จังหวัดที่ลงทะเบียน</th>
          <th colspan="2" nowrap>หน่วยบริการปฐมภูมิ</th>
          <th colspan="2" nowrap>หน่วยบริการประจำ</th>
          <th colspan="2" nowrap>หน่วยบริการที่รับการส่งต่อ</th>
        </tr>
        <tr>
          <th>รหัส</th>
          <th>ชื่อจังหวัด</th>
          <th>รหัส</th>
          <th nowrap>ชื่อหน่วยบริการ</th>
          <th>รหัส</th>
          <th nowrap>ชื่อหน่วยบริการ</th>
          <th>รหัส</th>
          <th nowrap>ชื่อหน่วยบริการ</th>
        </tr>
        </thead>
        <tbody>
        @if(pageData?.content?.length <= 0){
          <tr>
            <td nowrap colspan="16">ไม่มีข้อมูล</td>
          </tr>
        } @else {
          @for (data of pageData?.content; let i = $index; track data.transId){
            <tr>
              <td nowrap class="text-center">{{ (pageData.size * pageData.number) + (i + 1) }}</td>
              <td nowrap>{{data.createdDateTime | thaidatetime:{showTime:false} }}</td>
              <td>{{data.startDate | thaidatetime:{showTime:false} }}</td>
              @if (data.registrantType === "SELF") {
                <td nowrap>ลงทะเบียนด้วยตนเอง</td>
              } @else if (data.registrantType === "FAMILY"){
                <td nowrap>ลงทะเบียนโดยบุคคลในครอบครัว</td>
              }@else{
                <td>-</td>
              }
              <td nowrap>{{data?.personalId || "-"}}</td>
              <td nowrap>{{data?.titleName}}{{data.firstName}} {{data.lastName}}</td>
              <td nowrap>{{data?.regProvinceId || "-"}}</td>
              <td nowrap>{{data?.regProvinceName || "-"}}</td>
              <td nowrap>{{data?.hospSubCode || "-"}}</td>
              <td nowrap>{{data?.hospSubName || "-"}}</td>
              <td nowrap>{{data?.hospMainOpCode || "-"}}</td>
              <td nowrap>{{data?.hospMainOpName || "-"}}</td>
              <td nowrap>{{data?.hospMainCode || "-"}}</td>
              <td nowrap>{{data?.hospMainName || "-"}}</td>
              <td nowrap>{{data?.mainInsclId || "-"}}</td>
              <td nowrap>{{data?.subInsclId || "-"}}</td>
            </tr>
          }
        }
        </tbody>
      </table>
    </div>
  </div>
  <div class="row justify-contnt-between">
    <div class="col-xl-6 text-muted">
      (รายการที่พบทั้งหมด {{ pageData?.content?.length || 0 }} รายการ , หน้า : {{ pageData?.number + 1 }}
      / {{ pageData.totalPages || 0 }})
    </div>
    <div class="col-xl-6">
      <ngb-pagination class="d-flex justify-content-end"
                      ngbAutofocus
                      [maxSize]="5"
                      [rotate]="true"
                      [ellipses]="false"
                      [boundaryLinks]="true"
                      [page]="pageData?.number + 1"
                      (pageChange)="search($event)"
                      [pageSize]="pageData?.size"
                      [collectionSize]="pageData?.totalElements"/>
    </div>
  </div>
</div>
