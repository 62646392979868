@if (mastercup()?.mastercupId) {
  @if (!mastercup()?.quota) {
    <small
      class="d-inline-flex px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">
      เปิด
    </small>
  } @else {
    <small
      class="d-inline-flex px-2 py-1 fw-semibold text-danger bg-danger bg-opacity-10 border border-danger border-opacity-10 rounded-2">
      พัก
    </small>
  }
}
