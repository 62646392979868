import {Component, inject, signal} from '@angular/core';
import {Router} from '@angular/router';
import {AddFamilyModalService} from './modal/add-family-modal/add-family-modal.service';
import {RelationshipRightsMember} from '../../../../generated-model/model';
import {RelationshipRightsMemberService} from '../../../service/relationship-rights-member.service';
import {ConfirmDialogService} from '../../../shere/commons/dialog/confirm-dialog/confirm-dialog.service';
import {filter} from 'rxjs';
import {ToastService} from '../../../shere/commons/toast/toast-service';

@Component({
  selector: 'app-select-family',
  imports: [],
  templateUrl: './select-family.component.html',
  standalone: true,
  styleUrl: './select-family.component.scss'
})
export class SelectFamilyComponent {
  public router: Router = inject(Router);
  private addFamilyModalService = inject(AddFamilyModalService)
  relationshipRightMemberService = inject(RelationshipRightsMemberService)
  confirmDialogService = inject(ConfirmDialogService)
  toastService = inject(ToastService)

  allFamilyMembers = signal([] as RelationshipRightsMember[])

  ngOnInit() {
    this.getAllFamily()
  }

  getAllFamily() {
    this.relationshipRightMemberService.getAllFamilyMember().subscribe({
      next: value => {
        this.allFamilyMembers.set(value)
      }
    })
  }

  select(member: RelationshipRightsMember) {
    this.router.navigate(['secure', 'select-family-member-detail'], {state: {...member}});
  }

  openAddFamilyModal() {
    this.addFamilyModalService.open().subscribe(
      {
        next: data => {
          this.getAllFamily()
          console.log(data)
        },
        error: err => {
          console.log(err)
        }
      }
    )
  }

  deleteMember(memberPid: string) {
    this.confirmDialogService.open({content: 'ยืนยันการลบบุคคลในครอบครัว ?'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
          this.relationshipRightMemberService.deleteMember(memberPid).subscribe({
            next: value => {
              this.toastService.showSuccess('ลบบุคคลในครอบครัวสำเร็จ')
              this.getAllFamily()
              console.log('delete success')
            }, error: err => {
              console.log(err)
            }
          })
        }
      })
  }

  edit(member: RelationshipRightsMember) {
    this.addFamilyModalService.edit(member).subscribe({
      next: data => {
        this.getAllFamily()
      }, error: err => {
        console.log(err)
      }
    })
  }
}
