import {FormGroup, NgForm} from "@angular/forms";
import {KeyValue} from "@angular/common";
import {StringUtils} from './shere/gt-ng-datepicker/utils/string-utils';
import {Control, icon, Icon, latLng, tileLayer} from "leaflet";

export function checkScreenSize() {
  const screenWidth = window.innerWidth;

  if (screenWidth < 576) {
    console.log('Current screen size: Extra Small (xs)');
  } else if (screenWidth >= 576 && screenWidth < 768) {
    console.log('Current screen size: Small (sm)');
  } else if (screenWidth >= 768 && screenWidth < 992) {
    console.log('Current screen size: Medium (md)');
  } else if (screenWidth >= 992 && screenWidth < 1200) {
    console.log('Current screen size: Large (lg)');
  } else {
    console.log('Current screen size: Extra Large (xl)');
  }
}

export function updateFormAndValidity(form: NgForm): boolean {
  if (!form) {
    return true;
  }
  console.log('updateFormAndValidity form ', form);
  if (!form.valid) {
    markInvalidControlsAsDirtyAndTouched(form)
  }
  return form.valid;
}

export function scrollToInvalidElement(formId: string) {
  if (!formId) {
    return;
  }
  let selector = `#${formId} .ng-invalid`;
  let invalidElement = document.querySelector(selector);
  invalidElement?.scrollIntoView({behavior: 'smooth'});
}

export function markInvalidControlsAsDirtyAndTouched(form: NgForm): void {
  Object.keys(form.controls)
    .map(key => {
      // console.log(`form.controls[${key}] invalid? `, form.controls[key].invalid);
      return key;
    })
    .filter(key => form.controls[key].invalid)
    .forEach((key, index) => {
      // console.log('form.controls[', key, '], invalid', form.controls[key], form.controls[key]?.invalid)
      if (form.controls[key] instanceof FormGroup) {
        let formGroup = form.controls[key] as FormGroup;
        // console.log('formGroup= ', formGroup);
        formGroup.markAsDirty({onlySelf: false});
        formGroup.markAsTouched({onlySelf: false});
        formGroup.updateValueAndValidity({emitEvent: true});
        Object.keys(formGroup.controls)
          .map(k => {
            // console.log(`formGroup.controls[${k}] invalid? `, k, formGroup.controls[k], formGroup.controls[k]?.invalid);
            return k;
          })
          .filter(k => formGroup.controls[k].invalid)
          .forEach((groupKey, index) => {
            // console.log('formGroup[', groupKey, '], invalid', formGroup.controls[groupKey], formGroup.controls[groupKey]?.invalid)
            formGroup.controls[groupKey].markAsDirty({onlySelf: false});
            formGroup.controls[groupKey].markAsTouched({onlySelf: false});
            formGroup.controls[groupKey].updateValueAndValidity({emitEvent: true});
          });
      } else {
        form.controls[key].markAsDirty({onlySelf: false})
        form.controls[key].markAsTouched({onlySelf: false})
        form.controls[key].updateValueAndValidity({emitEvent: true});
      }
    });
}

export type CRUD = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';

export const DayOfWeekThai: { [id: string]: string } = {
  MONDAY: 'จันทร์',
  TUESDAY: 'อังคาร',
  WEDNESDAY: 'พุธ',
  THURSDAY: 'พฤหัสบดี',
  FRIDAY: 'ศุกร์',
  SATURDAY: 'เสาร์',
  SUNDAY: 'อาทิตย์'
};

export function cloneObject(object: any): any {
  if (!object) {
    return undefined;
  }
  return JSON.parse(JSON.stringify(object))
}

export function mergeObject(obj1: any, obj2: any): any {
  Object.keys(obj2).filter(key => key in obj1).forEach(key => obj1[key] = obj2[key]);
  return obj1;
}

export type PageRequest = { size?: number, page?: number, sort?: string | string[] }
export const compareWithId = (o1: { id: any }, o2: {
  id: any
}): boolean => (o1 == o2) || ((!!o1 && !!o2) && (o1.id === o2.id));
export const compareWithCode = (o1: { code: any }, o2: {
  code: any
}): boolean => (o1 == o2) || ((!!o1 && !!o2) && (o1.code === o2.code));

// export function current_date(): string {
//   let now = new Date();
//   return `${now.getFullYear()}-${StringUtils.paddingZeroLeft(now.getMonth() + 1, 2)}-${StringUtils.paddingZeroLeft(now.getDate(), 2)}`;
// }

export function current_datetime(): string {
  let now = new Date();
  let date = `${now.getFullYear()}-${StringUtils.paddingZeroLeft(now.getMonth() + 1, 2)}-${StringUtils.paddingZeroLeft(now.getDate(), 2)}`;
  let time = `${StringUtils.paddingZeroLeft(now.getHours(), 2)}:${StringUtils.paddingZeroLeft(now.getMinutes(), 2)}:00`;
  return `${date}T${time}`
}

export function current_datetime_midnight(): string {
  let now = new Date();
  let date = `${now.getFullYear()}-${StringUtils.paddingZeroLeft(now.getMonth() + 1, 2)}-${StringUtils.paddingZeroLeft(now.getDate(), 2)}`;
  let time = `00:00:00`;
  return `${date}T${time}`
}

export type GeoLocation = { lat: number, lon: number };

export function notImplementedYet() {
  alert("not implemented yet.");
}

export const BKK_PROVINCE_ID = "1000";
export const SN = "SN";
export const SP = "SP";

export const SCARD_KEY = 'smartcard.terminal';
export const SCARD_READIMAGE_KEY = 'smartcard.readimage';
export const SCARD_AUTHEB_TYPE_KEY = 'smartcard.authenType';

export const MAP_ORDER_BY_KEY_NUMBER_ASC = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
  if (a.value && b.value) {
    return a.value.localeCompare(b.value);
  }
  return 0;
}

export const MAP_ORDER_BY_KEY_STRING_ASC = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
  // if (a.value && b.value) {
  //   return a.value.localeCompare(b.value);
  // }
  return 0;
}

export type MonthType = { name: string, value: number }
export const monthNames: MonthType[] = [
  {name: "มกราคม", value: 1},
  {name: "กุมภาพันธ์", value: 2},
  {name: "มีนาคม", value: 3},
  {name: "เมษายน", value: 4},
  {name: "พฤษภาคม", value: 5},
  {name: "มิถุนายน", value: 6},
  {name: "กรกฎาคม", value: 7},
  {name: "สิงหาคม", value: 8},
  {name: "กันยายน", value: 9},
  {name: "ตุลาคม", value: 10},
  {name: "พฤศจิกายน", value: 11},
  {name: "ธันวาคม", value: 12},
]

export const options = {
  layers: [
    tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: ''
    })
  ],
  zoom: 7,
  center: latLng(15.234630878675143, 100.66770102070303)
};

export const locateOptions: Control.LocateOptions = {
  flyTo: true,
  keepCurrentZoomLevel: false,
  initialZoomLevel: 10,
  locateOptions: {
    enableHighAccuracy: true,
  },
  icon: 'leaflet-control-locate-location-arrow',
  strings: {
    title: 'กดที่นี่เพื่อตรวจสอบพิกัดปัจจุบันของท่าน'
  },
  clickBehavior: {
    inView: 'stop',
    outOfView: 'setView',
    inViewNotFollowing: 'setView'
  }
};

export function customIcon(): Icon {
  return icon({
    ...Icon.Default.prototype.options,
    iconUrl: 'assets/images/marker-icon.png',
    iconRetinaUrl: 'assets/images/marker-icon-2x.png',
    shadowUrl: 'assets/images/marker-shadow.png'
  })
}
