import {Injectable} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MastercupCatm, SelectMastercupCatmComponent} from "./select-mastercup-catm.component";
import {defer, Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class SelectMastercupCatmService {

  constructor(private modalService: NgbModal) {
  }

  open(): Observable<MastercupCatm> {
    const modalRef = this.modalService.open(SelectMastercupCatmComponent, {
      // fullscreen: true,
      size: "lg",
      windowClass: 'custom-modal-content',
      backdrop: 'static',
      keyboard: false
    });
    return defer(() => modalRef.result);
  }
}
