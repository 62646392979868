import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi
} from '@angular/common/http';
import {NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CustomDatepickerI18n, I18n} from './shere/gt-ng-datepicker/custom-datepicker';
import {loadingInterceptor} from './interceptors/loading_interceptor';
import {Http400Interceptor} from './interceptors/http400-interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([
        loadingInterceptor,
      ]),
      withInterceptorsFromDi()
    ),
    I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    {provide: HTTP_INTERCEPTORS, useClass: Http400Interceptor, multi: true},
  ]
};
