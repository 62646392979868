import {
  AfterViewInit,
  Component,
  effect,
  forwardRef,
  inject,
  input,
  model,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {finalize, Observable, of} from "rxjs";
import {CommonModule} from "@angular/common";
import {District, SubDistrict} from '../../../../../../generated-model/model';
import {MastercupResourceService} from '../../../../../service/mastercup-resource.service';
import {SharedCommonModule} from '../../../../../shere/commons/shared-common.module';

@Component({
  selector: 'app-select-mastercup-district',
  templateUrl: './select-mastercup-district.component.html',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectMastercupDistrictComponent),
      multi: true
    }
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedCommonModule
  ]
})
export class SelectMastercupDistrictComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {

  districtList$: Observable<District[]> = of([]);
  typeaheadLoading = false;
  value: District = undefined
  id = input<string>('mastercup-district');
  name = input<string>('mastercup-district');
  provinceId = input<string>();
  disabled = input<boolean>();
  options = input<{ fetchAll?: boolean }>({fetchAll: false});
  private mastercupResource = inject(MastercupResourceService);
  onChange = (value) => {
  };
  onTouched = () => {
  };

  compareWithCode = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.code == o2.code))
  };

  constructor() {
    effect(() => {
      if (this.provinceId()) {
        this.onChange(undefined);
        this.districtList$ = this.mastercupResource.findDistrictAllByProvinceId(this.provinceId())
          .pipe(finalize(() => this.typeaheadLoading = false));
      }
    });
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  writeValue(value: SubDistrict) {
    this.value = value
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  valueChange(value: SubDistrict) {
    this.onChange(value);
    this.onTouched();
  }
}
