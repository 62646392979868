import {Component, inject, input, OnInit, signal} from '@angular/core';
import {Router} from '@angular/router';
import {RightCheckResource} from '../../service/right-check-resource.service';
import {RelationshipRightsMemberService} from '../../service/relationship-rights-member.service';
import {RegisterRequest, RegistrantType, RelationshipRightsMember, RightsDto} from '../../../generated-model/model';
import {
  SelfRightCheckDetailComponent
} from '../right/self-right-check/self-right-check-detail/self-right-check-detail.component';
import {
  FamilyRightCheckDetailComponent
} from '../right/family-right-check/family-right-check-detail/family-right-check-detail.component';
import {RightCheckComponent} from '../right/right-check/right-check.component';
import {RegisterFormComponent} from '../register-form/register-form.component';

@Component({
  selector: 'app-register',
  imports: [
    SelfRightCheckDetailComponent,
    FamilyRightCheckDetailComponent,
    RightCheckComponent,
    RegisterFormComponent
  ],
  templateUrl: './register.component.html',
  standalone: true,
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit {
  router = inject(Router)
  rightCheckResourceService = inject(RightCheckResource)
  relationRightMemberService = inject(RelationshipRightsMemberService)

  registrantType: RegistrantType
  pid: string

  request = signal<RegisterRequest>({})
  data = signal({} as RightsDto)
  member = signal(undefined as RelationshipRightsMember)

  ngOnInit(): void {
    this.registrantType = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation().extras.state['registrantType'] : this.router.lastSuccessfulNavigation.extras.state['registrantType'];
    this.request().registrantType = this.registrantType
    this.pid = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation().extras.state['pid'] : this.router.lastSuccessfulNavigation.extras.state['pid'];
    console.log('pid', this.pid)
    this.rightCheckResourceService.searchRight(this.pid).subscribe({
      next: value => {
        this.data.set(value)
      }, error: err => {
        if (err?.status == 400) {
          this.data.set(undefined);
          console.log(err)
        }
      }
    })
    if (this.registrantType == 'FAMILY') {
      this.relationRightMemberService.getMember(this.pid).subscribe({
        next: member => {
          this.member.set(member)
        }, error: err => {
          if (err?.status == 400) {
            this.member.set(undefined)
            console.log(err)
          }
        }
      })
    }
  }
}
