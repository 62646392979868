import {Component, effect, Input, signal} from '@angular/core';
import {Service} from '../../../../generated-model/model';
import {ThaiDateTimePipe} from '../../../shere/commons/thai-datetime.pipe';
import {SlicePipe} from '@angular/common';
import {NotFoundComponent} from '../../../shere/not-found/not-found.component';
import {NgbPagination} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-phr-benefit-detail',
  imports: [
    ThaiDateTimePipe,
    SlicePipe,
    NotFoundComponent,
    NgbPagination
  ],
  templateUrl: './phr-benefit-detail.component.html',
  standalone: true,
  styleUrl: './phr-benefit-detail.component.scss'
})
export class PhrBenefitDetailComponent {
  @Input() phrServices = signal([] as Service[])
  page: number = 0;
  pageSize: number = 10;
  totalPage : number = 0;
  constructor() {
    effect(() => {
      this.totalPage = Math.ceil(this.phrServices()?.length / this.pageSize)
    });
  }
}
