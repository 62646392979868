import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RegisterRequest} from '../../generated-model/model';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterResource {
  http = inject(HttpClient)

  registerNew(request: RegisterRequest) {
    return this.http.post(`${environment.serverUrl}/api/secure/register-new`, request)
  }

  editBoard(request: RegisterRequest) {
    return this.http.post(`${environment.serverUrl}/api/secure/edit-board`, request)
  }

  changeHospital(request: RegisterRequest) {
    return this.http.post(`${environment.serverUrl}/api/secure/change-hospital`, request)
  }

  acceptBoard(request: RegisterRequest) {
    return this.http.post(`${environment.serverUrl}/api/secure/accept-board`, request)
  }
}
