import {inject, Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {from} from 'rxjs';
import {SelectMastercupComponent} from './select-mastercup.component';
import {SubDistrictDto} from '../../../../../../generated-model/model';

@Injectable({
  providedIn: 'root'
})
export class SelectMastercupService {

  private modalService = inject(NgbModal)

  constructor() {
  }

  open(subDistrict:SubDistrictDto) {
    const modalRef = this.modalService.open(SelectMastercupComponent, {
      centered: true,
      size: "xl",
      keyboard: true
    });
    const component: SelectMastercupComponent = modalRef.componentInstance;
    component.subDistrict = subDistrict
    return from(modalRef.result)
  }
}
