<div class="card shadow-card mb-4">
  <div class="card-body">
    <div class="justify-content-xl-center justify-content-sm-start d-flex fw-bold mt-3">
      <input type="checkbox" class="form-check-input me-2" id="accept">
      <label for="accept" class="form-check-label">รับข้อตกลง และยืนยันการลงทะเบียน</label>
    </div>
  </div>
</div>
<div class="text-center mb-4">
  <div class="col-12">
    <button class="btn btn-success" type="button" (click)="confirmReg()">
      <i class="bi bi-floppy-fill me-1"></i>
      ยืนยันการลงทะเบียน
    </button>
  </div>
</div>
