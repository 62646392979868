import {inject, Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {defer} from 'rxjs';
import {PinnedAddressComponent} from './pinned-address.component';

@Injectable({
  providedIn: 'root'
})
export class PinnedAddressService {

  private modalService = inject(NgbModal)

  constructor() {
  }

  open() {
    const modalRef = this.modalService.open(PinnedAddressComponent, {
      size: 'xl',
      windowClass: 'custom-modal-content',
      backdrop: 'static',
      keyboard: false,
      fullscreen: 'xxl',
    });
    return defer(() => modalRef.result);
  }
}
