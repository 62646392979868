<form id="contract-vendor-modal-form" class="form-validate" #form="ngForm">
  <div class="modal-header">
    <h6 class="modal-title text-muted">
      เลือกเครือข่ายหน่วยบริการ
      @if (catmNear()) {
        เครือข่ายพื้นที่รอยต่อ
      }
    </h6>
    <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.close('Cross click')"></button>
  </div>
  <div class="modal-body overflow-hidden">
    <div class="table-responsive">
      <table class="table table-striped table-borderless table-hover caption-top">
        <caption></caption>
        <thead>
        <tr>
          <th nowrap scope="col" class="text-center">ลำดับ</th>
          <th nowrap scope="col"></th>
          <th nowrap scope="col">หน่วยบริการปฐมภูมิ</th>
          <th nowrap scope="col">หน่วยบริการประจำ</th>
        </tr>
        </thead>
        <tbody>
          @for (mastercup of pageData?.content; let i = $index; track mastercup.id) {
            <tr>
              <td nowrap class="text-center">{{ (pageData.size * pageData.number) + (i + 1) }}</td>
              <td nowrap>
                @if (mastercup.selectable) {
                  <button type="button" class="btn btn-default" (click)="select(mastercup)">
                    <i class="bi bi-box-arrow-down"></i> เลือก
                  </button>
                }
              </td>
              <td nowrap>{{ mastercup.hospSubDisplayName || '-' }}</td>
              <td nowrap>{{ mastercup.hospMainOpDisplayName || '-' }}</td>
            </tr>
          } @empty {
            <tr>
              <td colspan="9">
                <app-not-found></app-not-found>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <div class="row justify-content-between">
      <div class="col-xl-6 text-muted">
        (รายการที่พบทั้งหมด {{ pageData?.content?.length || 0 }} รายการ , หน้า : {{ pageData?.number + 1 }}
        / {{ pageData.totalPages || 0 }})
      </div>
      <div class="col-xl-6">
        <ngb-pagination class="d-flex justify-content-end"
                        ngbAutofocus
                        [maxSize]="5"
                        [rotate]="true"
                        [ellipses]="false"
                        [boundaryLinks]="true"
                        [page]="pageData?.number + 1"
                        (pageChange)="search($event)"
                        [pageSize]="pageData?.size"
                        [collectionSize]="pageData?.totalElements"/>
      </div>
    </div>
  </div>
</form>
